import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { store, persistor } from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import AppFooter from "./components/navbar/AppFooter";
import ResponsiveAppBar from "./components/navbar/ResponsiveAppBar";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ResponsiveAppBar />
          <App />
          <AppFooter />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);


