import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Button from "@mui/material/Button";
import { setDataCollection } from "../../../redux/actions/dataCollectionAction";

export default function UpdateContactModal({ show, handleClose = () => {} }) {
  const initialFieldsState = {
    Name: { Content: "", Score: "" },
    Phone: { Content: "", Score: "" },
    Email: {
      Content: "",
      Score: "",
    },
    Profile: {
      Content: "",
      Score: "",
    },
    YearsOfExperience: {
      Content: "",
      Score: "",
    },
    Address: {
      Content: "",
      Score: "",
    },
    Linkedin: {
      Content: "",
      Score: "",
    },
  };
  const [contactFields, setContactFields] = useState(initialFieldsState);

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();
  const getAbbreviationName = (userName) => {
    const name = Object.values(userName == null ? "" : userName)[0];
    if (name !== "") {
      const abrName = name.match(/\b\w/g).join(" ");
      return abrName;
    } else {
      return "";
    }
  };
  const handleRegisterButton = () => {
    const abrName = getAbbreviationName(contactFields?.Name);
    dispatch(
      setDataCollection({
        ...dataCollection,
        ...contactFields,
        abbreviationName: { Content: abrName, Score: "" },
      })
    );
    handleClose();
  };

  useEffect(() => {
    setContactFields({
      Name: dataCollection.Name,
      Phone: dataCollection.Phone,
      Email: dataCollection.Email,
      Profile: dataCollection.Profile,
      YearsOfExperience: dataCollection.YearsOfExperience,
      Address: dataCollection.Address,
      Linkedin: dataCollection.Linkedin,
    });
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            bgcolor: "#200A5F",
            color: "#200A5F",
          }}
        >
          Contact
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Nom
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              value={
                Object.values(
                  contactFields.Name == null ? 0 : contactFields.Name
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Name: {
                    ...contactFields.Name,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="nom"
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Phone
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              // value={Object.values(user.Phone == null ? 0 : user.Phone)[0]}
              value={
                Object.values(
                  contactFields.Phone == null ? 0 : contactFields.Phone
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Phone: {
                    ...contactFields.Phone,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="phone"
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                E-mail
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              // value={Object.values(user.Email == null ? 0 : user.Email)[0]}
              value={
                Object.values(
                  contactFields.Email == null ? 0 : contactFields.Email
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Email: {
                    ...contactFields.Email,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="email"
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Rôle
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              // value={
              //   Object.values(user.Profile == null ? 0 : user.Profile)[0]
              // }
              value={
                Object.values(
                  contactFields.Profile == null ? 0 : contactFields.Profile
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Profile: {
                    ...contactFields.Profile,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="rôle"
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                {`Nombre d'années d'expérience(s)`}
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              value={
                Object.values(
                  contactFields.YearsOfExperience == null
                    ? 0
                    : contactFields.YearsOfExperience
                )[0]
                // Object.values(
                //   user.YearsOfExperience == null ? 0 : user.YearsOfExperience
                // )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  YearsOfExperience: {
                    ...contactFields.YearsOfExperience,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="nombre d'années d'expérience(s)"
              autoFocus
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Résidence Principale
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              value={
                // Object.values(user.Address == null ? 0 : user.Address)[0]
                Object.values(
                  contactFields.Address == null ? 0 : contactFields.Address
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Address: {
                    ...contactFields.Address,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="résidence principale"
              autoFocus
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Linkedin
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              value={
                Object.values(
                  contactFields.Linkedin == null ? 0 : contactFields.Linkedin
                )[0]
              }
              onChange={(event) => {
                setContactFields({
                  ...contactFields,
                  Linkedin: {
                    ...contactFields.Linkedin,
                    Content: event.target.value,
                  },
                });
              }}
              placeholder="linkedin"
              autoFocus
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>
        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={handleRegisterButton}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
