import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { TextareaAutosize, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import CardContent from "@mui/material/CardContent";

import experienceIllustration from "../../../assets/experienceIllustration1.jpg";
import { setExperiences } from "../../../redux/actions/dataCollectionAction";
import {
  getReordredDescriptionDetailsInNewModal,
  reorder,
} from "./ExperienceUtilities";

const DeleteButton = ({ handleClick }) => (
  <span onClick={handleClick} style={{ cursor: "pointer", fontSize: "20px" }}>
    &times;
  </span>
);

export default function AddExperienceModal({
  openModal,
  handleAddExperienceClose = () => {},
}) {
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [technologies, setTechnologies] = useState([]);
  const [descriptions, setDescriptions] = useState([
    {
      Context: [""],
      Details: [""],
    },
  ]);

  const [parametersTechno, setParametersTechno] = useState([]);

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  const styles = {
    title: {
      color: "#fff",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      fontFamily: "FancyFont, sans-serif",
    },
    illustration: {
      width: "50px",
      height: "50px",
      marginRight: "10px",
      filter: "hue-rotate(150deg) saturate(200%)",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        transform: "scale(1.2)",
        filter: "brightness(110%)",
      },
    },
    header: {
      background: "linear-gradient(to right, #200A5F, #6A0080)",
    },
  };

  const addInputTechnologies = () => {
    setTechnologies((s) => {
      return [...s, ""];
    });
  };

  const addTechnologiesToNewExperience = (e, index) => {
    // if (e.target.value) {
    setTechnologies((s) => {
      let newArr = s.slice().filter((val) => val !== null && val !== "");
      newArr[index] = e.target.value;
      return newArr;
    });
    // }
  };

  const deleteTechnologieFromNexExperience = (element) => {
    setTechnologies(technologies.filter((item) => item !== element));
  };

  const addInputDescriptions = () => {
    setDescriptions([...descriptions, { Context: [""], Details: [""] }]);
  };

  const deleteDetailFromDescription = (element) => {
    setDescriptions(descriptions.filter((item) => item !== element));
  };

  const addContextToNewDescription = (e, indexDescription, index) => {
    setDescriptions((s) => {
      const newArr = [...s];
      newArr[indexDescription].Context[index] = e.target.value;
      return newArr;
    });
  };

  const addDetailToNewDescription = (e, indexDescription, index) => {
    setDescriptions((s) => {
      const newArr = [...s];
      newArr[indexDescription].Details[index] = e.target.value;
      return newArr;
    });
  };

  const addInputDeatilsByDescriptions = (event, index) => {
    setDescriptions((prevDescriptions) => {
      let updatedDescriptions = [...prevDescriptions];
      let selectedDescription = updatedDescriptions[index];
      selectedDescription.Details = [
        ...selectedDescription.Details,
        event.target.value,
      ];
      updatedDescriptions[index] = selectedDescription;
      return updatedDescriptions;
    });
  };

  const deleteInputDetailByDescriptions = (descIndex, DetailsIndex) => {
    setDescriptions((prevDescriptions) => {
      let updatedDescriptions = [...prevDescriptions];
      let selectedDescription = updatedDescriptions[descIndex];
      selectedDescription.Details = selectedDescription.Details.filter(
        (_, i) => i !== DetailsIndex
      );
      updatedDescriptions[descIndex] = selectedDescription;
      return updatedDescriptions;
    });
  };
  const setInitialStates = () => {
    setTechnologies([]);
    setDescriptions([
      {
        Context: [""],
        Details: [""],
      },
    ]);
    setPosition("");
    setCompany("");
    setDateStart("");
    setDateEnd("");
  };

  const addNewExperience = (newPosition, dateStart, dateEnd, newEntreprise) => {
    const newPeriode = dateStart.concat("    -   ", dateEnd);
    // let parentsArray = user.Experiences || [];
    const oldExperience = dataCollection.Experiences || [];
    const newExperience = [
      ...oldExperience,
      {
        Company: newEntreprise,
        Position: newPosition,
        Description: descriptions,
        Technologies: technologies,
        Period: newPeriode,
        Score: "12",
      },
    ];

    dispatch(setExperiences(newExperience));

    handleAddExperienceClose();
    // setUser({ ...user, Experiences: parentsArray });
  };

  const onDragEnd = ({ result, selectedType, descriptionIndex }) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let list = [];

    if (selectedType === "technologiesInNewExperienceModal") {
      list = technologies;
    } else if (selectedType === "descriptionInNewExperienceModal") {
      list = descriptions;
    } else if (selectedType === "descriptionDetailsInNewExperienceModal") {
      list = descriptions[descriptionIndex]?.Details;
    }

    const newList = reorder(
      list,
      result.source.index,
      result.destination.index
    );

    if (selectedType === "technologiesInNewExperienceModal") {
      setTechnologies(newList);
    } else if (selectedType === "descriptionInNewExperienceModal") {
      setDescriptions(newList);
    } else if (selectedType === "descriptionDetailsInNewExperienceModal") {
      const descriptionFiltred = getReordredDescriptionDetailsInNewModal(
        descriptions,
        newList,
        descriptionIndex
      );
      setDescriptions(descriptionFiltred);
    }
  };

  useEffect(() => {
    setPosition("");
    setCompany("");
    setDateStart("");
    setDateEnd("");
    setTechnologies([]);
    setDescriptions([
      {
        Context: [""],
        Details: [""],
      },
    ]);
  }, [openModal]);

  return (
    <Modal
      dialogClassName="modal-xl"
      show={openModal}
      onHide={handleAddExperienceClose}
    >
      <Modal.Header closeButton style={styles.header}>
        <Modal.Title style={styles.title}>
          <img src={experienceIllustration} style={styles.illustration} />
          <h2>Ajouter Experience</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Position */}
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Position
              </span>
            </Form.Label>
            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={position}
                        placeholder="position"
                        autoFocus
                        onChange={(e) => setPosition(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>

          {/* Entreprise */}
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Entreprise
              </span>
            </Form.Label>
            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={company}
                        placeholder="entreprise"
                        autoFocus
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
          {/* Date de début */}
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Date de début
              </span>
            </Form.Label>
            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={dateStart}
                        placeholder="date de début"
                        autoFocus
                        onChange={(e) => setDateStart(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
          {/* Date de Fin */}
          <Form.Group className="mb-1">
            <Form.Label size="sm" color="primary">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Date de Fin
              </span>
            </Form.Label>

            <div className="row">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <Grid mb={1}>
                      <Form.Control
                        xm={1}
                        size="sm"
                        type="text"
                        value={dateEnd}
                        placeholder="date de fin"
                        autoFocus
                        onChange={(e) => setDateEnd(e.target.value)}
                      />
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Form.Group>
          {/* Technologies */}
          <Form.Group
            className="mb-1 mt-3"
            controlId="exampleForm.ControlInput1"
          >
            <div className="row">
              <div className="col-6 col-md-9">
                <Form.Label size="sm">
                  <span
                    style={{
                      fontstyle: "oblique",
                      bgcolor: "#200A5F",
                      color: "#200A5F",
                    }}
                  >
                    Technologies
                  </span>
                </Form.Label>
              </div>
              <div className="col-6 col-md-3">
                <Grid mb={2}>
                  <Button
                    sx={{
                      bgcolor: "#200A5F",
                      color: "white",
                      ":hover": {
                        color: "#200A5F",
                        border: "1px solid #200A5F",
                      },
                    }}
                    onClick={() => addInputTechnologies()}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                    <span style={{ fontSize: "x-small" }}> Ajouter</span>
                  </Button>
                </Grid>
              </div>
            </div>

            <div>
              <DragDropContext
                onDragEnd={(result) =>
                  onDragEnd({
                    result,
                    selectedType: "technologiesInNewExperienceModal",
                  })
                }
              >
                <Droppable droppableId="list">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {technologies
                        .filter((val) => val !== null)
                        .map((element, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={parseInt(index)}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="row" key={index}>
                                  <div className="col-10 col-md-10">
                                    <input
                                      type="text"
                                      list="techList"
                                      value={element}
                                      onChange={(e) =>
                                        addTechnologiesToNewExperience(e, index)
                                      }
                                      style={{
                                        backgroundColor: "white",
                                        border: "1px solid gray",
                                        borderRadius: "5px",
                                        padding: "10px",
                                        fontSize: "16px",
                                        width: "100%",
                                        height: "30px",
                                      }}
                                    />
                                    <datalist id="techList">
                                      {parametersTechno.map((param) => (
                                        <option
                                          key={param.id}
                                          value={param.value}
                                        />
                                      ))}
                                    </datalist>
                                  </div>

                                  <div className="col-2 col-md-2">
                                    <Grid mb={1}>
                                      <Grid mx={-3}>
                                        <Button
                                          onClick={() =>
                                            deleteTechnologieFromNexExperience(
                                              element
                                            )
                                          }
                                        >
                                          <span style={{ fontSize: "small" }}>
                                            <DeleteOutlinedIcon
                                              style={{ color: "#DA2889" }}
                                            />
                                          </span>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Form.Group>

          {/* Descriptions */}
          <Form.Group
            className="mb-1 mt-3"
            controlId="exampleForm.ControlInput1"
          >
            <div className="row">
              <div className="col-6 col-md-9">
                <Form.Label size="sm">
                  <span
                    style={{
                      fontstyle: "oblique",
                      bgcolor: "#200A5F",
                      color: "#200A5F",
                    }}
                  >
                    Descriptions
                  </span>
                </Form.Label>
              </div>
              <div className="col-6 col-md-3">
                <Grid mb={2}>
                  <Button
                    sx={{
                      bgcolor: "#200A5F",
                      color: "white",
                      ":hover": {
                        color: "#200A5F",
                        border: "1px solid #200A5F",
                      },
                    }}
                    onClick={addInputDescriptions}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                    <span style={{ fontSize: "x-small" }}> Ajouter</span>
                  </Button>
                </Grid>
              </div>
            </div>

            <div>
              <DragDropContext
                onDragEnd={(result) =>
                  onDragEnd({
                    result,
                    selectedType: "descriptionInNewExperienceModal",
                  })
                }
              >
                <Droppable droppableId="list">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {descriptions.length &&
                        descriptions?.map((element, key) => (
                          <Draggable
                            key={key}
                            draggableId={key.toString()}
                            index={parseInt(key)}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div key={key}>
                                  <Card
                                    style={{
                                      root: {
                                        minWidth: 275,
                                      },
                                    }}
                                  >
                                    <div className="row">
                                      <div className="col-md-11"></div>
                                      <div className="col-md-1">
                                        <DeleteButton
                                          handleClick={() =>
                                            deleteDetailFromDescription(element)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="card-number"
                                      style={{
                                        fontSize: "30px",
                                        fontWeight: "bold",
                                        color: "#DA2889",
                                        WebkitBackgroundClip: "text",
                                        textAlign: "center",
                                      }}
                                    >
                                      {key + 1}
                                    </div>
                                    <CardContent>
                                      <Typography variant="body2" component="p">
                                        {element.Context.map((ctx, keyc) => (
                                          <div className="row" key={keyc}>
                                            <div className="col-md-8">
                                              <TextareaAutosize
                                                style={{
                                                  width: "100%",
                                                  backgroundColor: "#f3f4fa",
                                                }}
                                                minRows={3}
                                                type="text"
                                                value={ctx}
                                                id={keyc}
                                                placeholder="Nom du projet"
                                                autoFocus
                                                onChange={(e) =>
                                                  addContextToNewDescription(
                                                    e,
                                                    key,
                                                    keyc
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        ))}

                                        <DragDropContext
                                          onDragEnd={(result) =>
                                            onDragEnd({
                                              result,
                                              selectedType:
                                                "descriptionDetailsInNewExperienceModal",
                                              descriptionIndex: key,
                                            })
                                          }
                                        >
                                          <Droppable droppableId="list">
                                            {(provided, snapshot) => (
                                              <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                              >
                                                {element.Details.map(
                                                  (ctx, keyc) => (
                                                    <Draggable
                                                      key={keyc}
                                                      draggableId={keyc.toString()}
                                                      index={parseInt(keyc)}
                                                    >
                                                      {(provided, snapshot) => (
                                                        <div
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                        >
                                                          <div
                                                            className="row"
                                                            key={keyc}
                                                          >
                                                            <div className="col-md-8">
                                                              <TextareaAutosize
                                                                style={{
                                                                  width: "100%",
                                                                  backgroundColor:
                                                                    "#f3f4fa",
                                                                }}
                                                                minRows={3}
                                                                type="text"
                                                                id={keyc}
                                                                value={ctx}
                                                                onChange={(e) =>
                                                                  addDetailToNewDescription(
                                                                    e,
                                                                    key,
                                                                    keyc
                                                                  )
                                                                }
                                                                placeholder="Description"
                                                                autoFocus
                                                              />
                                                            </div>
                                                            <div className="col-md-4 pt-2">
                                                              <Grid ml={-1}>
                                                                <div>
                                                                  <Button
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      addInputDeatilsByDescriptions(
                                                                        e,
                                                                        key
                                                                      )
                                                                    }
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "xx-small",
                                                                      }}
                                                                    >
                                                                      <AddBoxOutlinedIcon
                                                                        style={{
                                                                          color:
                                                                            "#200A5F",
                                                                        }}
                                                                      />
                                                                    </span>
                                                                  </Button>

                                                                  <Button
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      deleteInputDetailByDescriptions(
                                                                        key,
                                                                        keyc
                                                                      )
                                                                    }
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize:
                                                                          "xx-small",
                                                                      }}
                                                                    >
                                                                      <DeleteOutlinedIcon
                                                                        style={{
                                                                          color:
                                                                            "#DA2889",
                                                                        }}
                                                                      />
                                                                    </span>
                                                                  </Button>
                                                                </div>
                                                              </Grid>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                                {provided.placeholder}
                                              </div>
                                            )}
                                          </Droppable>
                                        </DragDropContext>
                                      </Typography>
                                    </CardContent>
                                  </Card>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={() =>
            addNewExperience(position, dateStart, dateEnd, company)
          }
        >
          Enregistrer
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
