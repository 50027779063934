import {
  RESET_DATA_COLLECTION,
  SET_CERTIFICATIONS,
  SET_DATA_COLLECTION,
  SET_DESCRIPTION,
  SET_EDUCATIONS,
  SET_EXPERIENCES,
  SET_HOBBIES,
  SET_LANGUAGES,
  SET_PROFIL_IMAGE,
  SET_SKILLS,
} from "../actions/dataCollectionAction";

const initialState = {
  Education: [],
  Certifications: [],
  Email: {
    Content: "",
    Score: "",
  },
  Experiences: [],
  Description: {},
  Hobbies: [],
  Languages: [],
  Linkedin: "",
  Name: {
    Content: "",
    Score: "",
  },
  fileName: "",
  abbreviationName: { Content: "", Score: "" },
  OtherInformations: [],
  OtherWebsites: [],
  Phone: { Content: "", Score: "" },
  Profile: {
    Content: "",
    Score: "",
  },
  profilImage: "",
  Skills: [],
  YearsOfExperience: {
    Content: "",
    Score: "",
  },
};

const dataCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA_COLLECTION:
      return {
        ...state,
        ...action.payload.data,
      };
    case SET_SKILLS:
      return {
        ...state,
        Skills: action.payload.skills,
      };
    case SET_PROFIL_IMAGE:
      return {
        ...state,
        profilImage: action.payload.profilImage,
      };
    case SET_EDUCATIONS:
      return {
        ...state,
        Education: action.payload.educationList,
      };
    case SET_CERTIFICATIONS:
      return {
        ...state,
        Certifications: action.payload.certificationList,
      };
    case SET_EXPERIENCES:
      return {
        ...state,
        Experiences: action.payload.experienceList,
      };
    case SET_DESCRIPTION:
      return {
        ...state,
        Description: action.payload.description,
      };
    case SET_HOBBIES:
      return {
        ...state,
        Hobbies: action.payload.hobbies,
      };
    case SET_LANGUAGES:
      return {
        ...state,
        Languages: action.payload.languages,
      };

    case RESET_DATA_COLLECTION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default dataCollectionReducer;
