function getReorderedDescriptionContext(
  selectedItem,
  newList,
  descriptionIndex
) {
  const selectedDesciption = selectedItem?.Description || [];
  if (selectedDesciption.length === 0) {
    return selectedItem?.Description;
  }
  const reordredDescriptionByContext = selectedItem?.Description?.map(
    (descriptionElem, index) => {
      if (descriptionIndex.toString() === index.toString()) {
        return { ...descriptionElem, Context: newList };
      } else {
        return descriptionElem;
      }
    }
  );
  return reordredDescriptionByContext;
}
// function getReorderedDescriptionContext(
//   experiences,
//   selectedItemId,
//   newList,
//   descriptionIndex
// ) {
//   const experiencesfiltred = Object.keys(experiences).reduce(
//     (accum, initialVal) => {
//       if (initialVal === selectedItemId) {
//         const newDescription = Object.keys(
//           experiences[initialVal].Description
//         ).reduce((accumulator, currentValue) => {
//           if (currentValue.toString() === descriptionIndex.toString()) {
//             accumulator = [
//               ...accumulator,
//               {
//                 ...experiences[initialVal].Description[currentValue],
//                 Context: newList,
//               },
//             ];
//           } else {
//             accumulator = [
//               ...accumulator,
//               { ...experiences[initialVal].Description[currentValue] },
//             ];
//           }
//           return accumulator;
//         }, []);
//         accum = [
//           ...accum,
//           { ...experiences[initialVal], Description: newDescription },
//         ];
//         return accum;
//       } else {
//         accum = [...accum, { ...experiences[initialVal] }];
//         return accum;
//       }
//     },
//     []
//   );

//   return experiencesfiltred;
// }
function getReordredDescriptionDetailsInNewModal(
  description,
  newList,
  descriptionIndex
) {
  const descriptionfiltred = Object.keys(description).reduce(
    (accum, initialVal) => {
      if (initialVal.toString() === descriptionIndex.toString()) {
        accum = [...accum, { ...description[initialVal], Details: newList }];

        return accum;
      } else {
        accum = [...accum, { ...description[initialVal] }];
        return accum;
      }
    },
    []
  );
  return descriptionfiltred;
}

function getReorderedDescriptionDetail(
  selectedItem,
  newList,
  descriptionIndex
) {
  const selectedDesciption = selectedItem?.Description || [];
  if (selectedDesciption.length === 0) {
    return selectedItem?.Description;
  }
  const reordredDescriptionByDetails = selectedItem?.Description?.map(
    (descriptionElem, index) => {
      if (descriptionIndex.toString() === index.toString()) {
        return { ...descriptionElem, Details: newList };
      } else {
        return descriptionElem;
      }
    }
  );
  return reordredDescriptionByDetails;
}
// function getReorderedDescriptionDetail(
//   experiences,
//   selectedItemId,
//   newList,
//   descriptionIndex
// ) {
//   const experiencesfiltred = Object.keys(experiences).reduce(
//     (accum, initialVal) => {
//       if (initialVal === selectedItemId) {
//         const newDescription = Object.keys(
//           experiences[initialVal].Description
//         ).reduce((accumulator, currentValue) => {
//           if (currentValue.toString() === descriptionIndex.toString()) {
//             accumulator = [
//               ...accumulator,
//               {
//                 ...experiences[initialVal].Description[currentValue],
//                 Details: newList,
//               },
//             ];
//           } else {
//             accumulator = [
//               ...accumulator,
//               { ...experiences[initialVal].Description[currentValue] },
//             ];
//           }
//           return accumulator;
//         }, []);
//         accum = [
//           ...accum,
//           { ...experiences[initialVal], Description: newDescription },
//         ];
//         return accum;
//       } else {
//         accum = [...accum, { ...experiences[initialVal] }];
//         return accum;
//       }
//     },
//     []
//   );

//   return experiencesfiltred;
// }

function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

const getFinalEditedOrderExperienceParams = ({
  selectedItem,
  result,
  selectedType,
  descriptionIndex,
}) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }
  let list = [];

  if (selectedType === "technologies") {
    list = selectedItem?.Technologies;
  } else if (selectedType === "description") {
    list = selectedItem?.Description;
  } else if (selectedType === "descriptionContext") {
    list = selectedItem?.Description[descriptionIndex]?.Context;
  } else if (selectedType === "descriptionDetail") {
    list = selectedItem?.Description[descriptionIndex]?.Details;
  }

  const newList = reorder(list, result.source.index, result.destination.index);

  if (selectedType === "technologies") {
    return { ...selectedItem, Technologies: newList };
  } else if (selectedType === "description") {
    return { ...selectedItem, Description: newList };
  } else if (selectedType === "descriptionContext") {
    const reordredDescriptionByContext = getReorderedDescriptionContext(
      selectedItem,
      newList,
      descriptionIndex
    );
    return {
      ...selectedItem,
      Description: reordredDescriptionByContext,
    };
  } else if (selectedType === "descriptionDetail") {
    const reordredDescriptionByDetails = getReorderedDescriptionDetail(
      selectedItem,
      newList,
      descriptionIndex
    );
    return {
      ...selectedItem,
      Description: reordredDescriptionByDetails,
    };
  }
};

const deleteSelectedDetailInEditModalExperirnce = (
  selectedExperience,
  descriptionIndex,
  detailIndex
) => {
  const descriptionDetails =
    selectedExperience?.Description[descriptionIndex]?.Details || [];
  const oldDescriptionElement =
    selectedExperience?.Description[descriptionIndex];
  const oldDescription = selectedExperience?.Description;
  const newDescription = oldDescription?.map((elem, index) => {
    if (index?.toString() === descriptionIndex) {
      const newDetails = descriptionDetails?.filter(
        (contextElem, indexElem) =>
          indexElem.toString() !== detailIndex.toString()
      );
      return {
        ...oldDescriptionElement,
        Details: newDetails,
      };
    } else {
      return elem;
    }
  });
  return { ...selectedExperience, Description: newDescription };
};
function updateSelectedDetailInEditModalExperirnce(
  selectedExperience,
  descriptionIndex,
  detailIndex,
  event
) {
  const descriptionDetails =
    selectedExperience?.Description[descriptionIndex]?.Details || [];
  const oldDescriptionElement =
    selectedExperience?.Description[descriptionIndex];
  const oldDescription = selectedExperience?.Description;
  const newDescription = oldDescription?.map((elem, index) => {
    if (index?.toString() === descriptionIndex) {
      const newDetails = descriptionDetails?.map((contextElem, indexElem) => {
        if (indexElem.toString() === detailIndex.toString()) {
          return event.target.value;
        } else {
          return contextElem;
        }
      });
      return {
        ...oldDescriptionElement,
        Details: newDetails,
      };
    } else {
      return elem;
    }
  });
  return { ...selectedExperience, Description: newDescription };
}

export {
  reorder,
  getReorderedDescriptionDetail,
  getReorderedDescriptionContext,
  getFinalEditedOrderExperienceParams,
  getReordredDescriptionDetailsInNewModal,
  updateSelectedDetailInEditModalExperirnce,
  deleteSelectedDetailInEditModalExperirnce,
};
