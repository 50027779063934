import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setDataCollection } from "../../../redux/actions/dataCollectionAction";
import UpdateSkillModal from "./UpdateSkillModal";
import AddSkillModal from "./AddSkillModal";

export default function Skills({ collapsIcon, setCollapsIcon = () => {} }) {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const [openModal, setopenModal] = useState(false);
  const handleAddSkillsClose = () => setopenModal(false);
  const [selectedSkillId, setSelectedSkillId] = useState(null);

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  const handleClose = () => setShowUpdateModal(false);

  const handleSelect = (item) => {
    setSelectedItem(item);
    setShowUpdateModal(true);
  };

  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  const deleteCertification = (index) => {
    if (index !== -1) {
      const parentsArray = dataCollection?.Skills?.filter(
        (elem, key) => key.toString() !== index
      );
      dispatch(
        setDataCollection({
          ...dataCollection,
          Skills: parentsArray,
        })
      );
    }
  };

  const addSkills = () => {
    setopenModal(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result, selectSkill) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const list =
      selectSkill === "detailsSkillItem"
        ? selectedItem?.Skills
        : dataCollection?.Skills;
    const newList = reorder(
      list,
      result.source.index,
      result.destination.index
    );

    if (selectSkill === "detailsSkillItem") {
      setSelectedItem({ ...selectedItem, Skills: newList });
    } else {
      dispatch(
        setDataCollection({
          ...dataCollection,
          Skills: newList,
        })
      );
    }
  };

  // useEffect(() => {
  //   const savedUserInformation = localStorage.getItem("cvUser");
  //   if (savedUserInformation) {
  //     const _user = JSON.parse(savedUserInformation);
  //     setUser(_user);
  //   }
  // }, []);
  // useEffect(() => {
  //   localStorage.setItem("cvUser", JSON.stringify(user));
  // }, [user]);

  return (
    <Card>
      <CardContent sx={{ bgcolor: "#f3f4fa" }}>
        <div className="row ">
          <div className="col-4 col-md-2 col-lg-2  d-flex justify-content-start align-items-center ">
            <p
              className="mt-3"
              style={{
                fontstyle: "oblique",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              Skills
            </p>
            <span
              style={{
                backgroundColor: "#DA2889",
                maxHeight: "23px",
                minHeight: "23px",
                minWidth: "23px",
                color: "white",
                borderRadius: "50%",
                fontSize: "12px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {dataCollection.Skills !== null
                ? dataCollection.Skills.length < 100
                  ? dataCollection.Skills.length
                  : "99+"
                : 0}
            </span>
          </div>
          <div className="col-3 col-md-7 col-lg-8"></div>
          <div className="col-5 col-md-3 col-lg-2 d-flex justify-content-around align-items-center">
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#DA2889",
                  color: "#DA2889",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                sx={{ color: "white" }}
                onClick={addSkills}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <AddIcon />
                  </div>

                  <div className="d-none d-md-block">Ajouter</div>
                </div>
              </Button>
            </div>
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#1976d2",
                  color: "#1976d2",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setCollapsIcon({
                    ...collapsIcon,
                    skills: !collapsIcon.skills,
                  });
                }}
                sx={{ color: "white" }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {collapsIcon.skills ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>

                  {/* <div>Modifier</div> */}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
      {
        //Check if message failed
        dataCollection.Skills != null && collapsIcon.skills ? (
          <div>
            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, "skillItem")}
            >
              <Droppable droppableId="list">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {dataCollection?.Skills &&
                      Object.entries(dataCollection?.Skills).map(
                        ([key, value]) => (
                          <Draggable
                            key={key}
                            draggableId={key.toString()}
                            index={parseInt(key)}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Box key={key}>
                                  <div>
                                    <div className="row">
                                      <div className="col-7 col-md-10">
                                        <Stack
                                          spacing={2}
                                          mt={1}
                                          ml={3}
                                          direction="row"
                                        >
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "medium",
                                              color: "#a9bef0",
                                            }}
                                          >
                                            {value.Name}
                                          </p>
                                        </Stack>
                                        <Stack
                                          spacing={2}
                                          ml={2}
                                          mt={1}
                                          direction="row"
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              width: "100%",
                                              flexWrap: "wrap",
                                            }}
                                          >
                                            {value?.Skills?.map(
                                              (x, skillValueIndex) => (
                                                <div
                                                  style={{ padding: "5px" }}
                                                  key={skillValueIndex}
                                                >
                                                  <Chip
                                                    key={skillValueIndex}
                                                    label={x}
                                                    style={{
                                                      backgroundColor:
                                                        "#f3f4fa",
                                                    }}
                                                  ></Chip>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </Stack>
                                      </div>

                                      <div className="col-5 col-md-2">
                                        <div className="row">
                                          <div className="col-6 col-md-6 mx-auto mt-4">
                                            <Button
                                              style={{
                                                maxHeight: "2.3em",
                                                minHeight: "2.0em",
                                                minWidth: "2em",
                                                textTransform: "capitalize",
                                                border: "2px solid transparent",
                                                borderColor: "#DA2889",
                                                color: "#DA2889",
                                                fontweight: "bold",
                                                backgroundColor: "white",
                                                borderRadius: "5px",
                                              }}
                                              onClick={() => {
                                                setSelectedSkillId(key);
                                                handleSelect({
                                                  ...value,
                                                  // id: key,
                                                });
                                              }}
                                            >
                                              <CreateIcon
                                                style={{
                                                  fontSize: "medium",
                                                }}
                                              />
                                            </Button>
                                          </div>
                                          <div className="col-6 col-md-6 mx-auto  mt-4">
                                            <Button
                                              style={{
                                                maxHeight: "2.3em",
                                                minHeight: "2.0em",
                                                minWidth: "2em",
                                                textTransform: "capitalize",
                                                border: "2px solid transparent",
                                                borderColor: "#686868",
                                                color: "#686868",
                                                fontweight: "bold",
                                                backgroundColor: "white",
                                                borderRadius: "5px",
                                              }}
                                              onClick={() =>
                                                deleteCertification(key)
                                              }
                                            >
                                              <DeleteIcon
                                                style={{
                                                  fontSize: "medium",
                                                }}
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <Grid
                                    item
                                    container
                                    rowSpacing={1}
                                    mt={1}
                                    columnSpacing={{ xs: 5, sm: 2, md: 3 }}
                                  ></Grid>
                                  <Grid
                                    container
                                    item
                                    xs={10}
                                    spacing={2}
                                    mt={-2}
                                    ml={3}
                                    direction="row"
                                  >
                                    <hr
                                      color="blue"
                                      style={{ color: "#a9bef0" }}
                                    ></hr>
                                  </Grid>
                                </Box>
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <div> </div>
        )
      }
      {/* Update Skills */}

      <UpdateSkillModal
        selectedItem={selectedItem}
        selectedSkillId={selectedSkillId}
        show={showUpdateModal}
        handleClose={handleClose}
        setSelectedItem={(elem) => setSelectedItem(elem)}
        onDragEnd={(result) => onDragEnd(result, "detailsSkillItem")}
      />

      {/* Add Skills */}

      <AddSkillModal
        openModal={openModal}
        handleAddSkillsClose={handleAddSkillsClose}
      />
    </Card>
  );
}
