import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Conditions d'utilisation
        </Typography>
        <Typography variant="body1" paragraph>
          Bienvenue sur CVerter. En utilisant notre service, vous acceptez de respecter et d'être lié par les conditions d'utilisation suivantes.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Ces termes et conditions régissent l'utilisation de notre application. En utilisant notre application, vous acceptez ces termes et conditions dans leur intégralité.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          2. Utilisation de l'application
        </Typography>
        <Typography variant="body1" paragraph>
          Vous ne devez pas utiliser notre application d'une manière qui cause, ou pourrait causer, des dommages à l'application ou une altération de la disponibilité ou de l'accessibilité de l'application.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          3. Propriété intellectuelle
        </Typography>
        <Typography variant="body1" paragraph>
          Sauf indication contraire, nous possédons les droits de propriété intellectuelle sur l'application.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
