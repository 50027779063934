import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { setSkills } from "../../../redux/actions/dataCollectionAction";

export default function UpdateSkillModal({
  selectedItem,
  selectedSkillId,
  show,
  handleClose = () => {},
  setSelectedItem = () => {},
  onDragEnd = () => {},
}) {
  const dataCollection = useSelector((state) => state.dataCollection);
  const dispatch = useDispatch();

  const handleSkills = (event, selectedSkills, formationElement) => {
    setSelectedItem({
      ...selectedSkills,
      [formationElement]: event.target.value,
    });
  };

  const addForComplexType = (event, selectedExperience, experienceElement) => {
    let skills = selectedItem?.Skills;
    setSelectedItem({
      ...selectedItem,
      Skills: [...skills, ""],
    });
  };

  const SkillshandleTechnologiesComplexeType = (event, key, selectedSkill) => {
    const skillsModified = selectedItem.Skills.map(
      (subSkill, subSkillIndex) => {
        if (subSkillIndex.toString() === key) return event.target.value;
        return subSkill;
      }
    );
    setSelectedItem({
      ...selectedSkill,
      Skills: skillsModified,
    });
  };

  const deleteComplexType = (key, selectedExperience) => {
    const parentsArray = dataCollection.Skills || [];
    const index = parentsArray
      .map((object) => object.Period)
      .indexOf(selectedExperience.Period);
    if (index !== -1) {
      const modifiedSkills = selectedItem?.Skills.filter(
        (skill, index) => index.toString() !== key
      );

      setSelectedItem({
        ...selectedItem,
        Skills: modifiedSkills,
      });
    }
  };

  const handleEditSkillsButton = () => {
    const dataCollectionSkills = dataCollection?.Skills.map((elem, index) => {
      if (index.toString() === selectedSkillId)
        return {
          ...selectedItem,
          Skills: selectedItem?.Skills.filter((elem) => elem !== ""),
        };
      return elem;
    });
    dispatch(setSkills(dataCollectionSkills));
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            bgcolor: "#200A5F",
            color: "#200A5F",
          }}
        >
          Compétences
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {
            //Check if message failed
            // user.Skills != null ? (
            dataCollection?.Skills != null ? (
              <div>
                <Form.Group className="mb-1">
                  <Form.Label size="sm" color="primary">
                    <span
                      style={{
                        fontstyle: "oblique",
                        bgcolor: "#200A5F",
                        color: "#200A5F",
                      }}
                    >
                      Compétence
                    </span>
                  </Form.Label>
                  <div className="row">
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <Grid mb={1}>
                            <Form.Control
                              xm={1}
                              size="sm"
                              type="text"
                              value={selectedItem?.Name}
                              placeholder="compétence"
                              autoFocus
                              onChange={(e) =>
                                handleSkills(e, selectedItem, "Name")
                              }
                            />
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
            ) : (
              <div> </div>
            )
          }
          <Form.Group
            className="mb-1 mt-3"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label size="sm">
              <span
                style={{
                  fontstyle: "oblique",
                  bgcolor: "#200A5F",
                  color: "#200A5F",
                }}
              >
                Skills
              </span>
            </Form.Label>

            {dataCollection?.Skills[selectedSkillId]?.Skills ? (
              <div>
                <div className="row">
                  <div className="col-md-9"></div>
                  <div className="col-md-3">
                    <Grid mb={2}>
                      <Button
                        sx={{ bgcolor: "#200A5F", color: "white" }}
                        onClick={(e) =>
                          addForComplexType(e, selectedItem, "Skills")
                        }
                      >
                        <AddCircleOutlineIcon fontSize="small" />
                        <span style={{ fontSize: "x-small" }}> Ajouter</span>
                      </Button>
                    </Grid>
                  </div>
                </div>

                <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                  <Droppable droppableId="list">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {Object.entries(selectedItem?.Skills).map(
                          ([key, value]) => (
                            <Draggable
                              key={key}
                              draggableId={key.toString()}
                              index={parseInt(key)}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div className="row" key={key}>
                                    <div className="col-md-11">
                                      <Grid mb={1}>
                                        <Form.Control
                                          xm={1}
                                          size="sm"
                                          type="text"
                                          value={value}
                                          placeholder="Skills"
                                          onChange={(e) =>
                                            SkillshandleTechnologiesComplexeType(
                                              e,
                                              key,
                                              selectedItem
                                            )
                                          }
                                          autoFocus
                                        />
                                      </Grid>
                                    </div>

                                    <div className="col-md-1">
                                      <Grid mb={1}>
                                        <Grid mx={-3}>
                                          <Button
                                            onClick={() => {
                                              deleteComplexType(
                                                key,
                                                selectedItem
                                              );
                                            }}
                                          >
                                            <span style={{ fontSize: "small" }}>
                                              x
                                            </span>
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            ) : (
              <div></div>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={handleEditSkillsButton}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
