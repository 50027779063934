import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardContent from "@mui/material/CardContent";

import experienceIllustration from "../../../assets/experienceIllustration1.jpg";
import { setExperiences } from "../../../redux/actions/dataCollectionAction";
import {
  deleteSelectedDetailInEditModalExperirnce,
  getFinalEditedOrderExperienceParams,
  getReorderedDescriptionContext,
  getReorderedDescriptionDetail,
  updateSelectedDetailInEditModalExperirnce,
} from "./ExperienceUtilities";
import { TextareaAutosize } from "@mui/material";

const DeleteButton = ({ handleClick }) => (
  <span onClick={handleClick} style={{ cursor: "pointer", fontSize: "20px" }}>
    &times;
  </span>
);

// const useStyles = makeStyles((theme) => ({
//   card: {
//     maxWidth: "90%",
//     margin: "auto",
//     marginTop: theme.spacing(5),
//     paddingBottom: theme.spacing(2),
//   },
//   title: {
//     margin: theme.spacing(2),
//     color: theme.palette.openTitle,
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1),
//     width: "90%",
//   },
//   submit: {
//     margin: theme.spacing(2),
//   },
//   subheading: {
//     marginTop: theme.spacing(2),
//     color: theme.palette.openTitle,
//   },
//   error: {
//     marginTop: theme.spacing(1),
//     color: theme.palette.error.main,
//   },
// }));

export default function UpdateExperienceModal({
  show,
  selectedItem,
  experienceId,
  handleClose = () => {},
  setSelectedItem = () => {},
}) {
  const [parametersTechno, setParametersTechno] = useState([]);

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  // const classes = useStyles();

  const styles = {
    title: {
      color: "#fff",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      fontFamily: "FancyFont, sans-serif",
    },
    illustration: {
      width: "50px",
      height: "50px",
      marginRight: "10px",
      filter: "hue-rotate(150deg) saturate(200%)",
      transition: "all 0.2s ease-in-out",
      "&:hover": {
        transform: "scale(1.2)",
        filter: "brightness(110%)",
      },
    },
    header: {
      background: "linear-gradient(to right, #200A5F, #6A0080)",
    },
  };

  const handleExperience = (event, selectedExperience, experienceElement) => {
    setSelectedItem({
      ...selectedExperience,
      [experienceElement]: event.target.value,
    });
  };

  const addForComplexType = (event, selectedExperience, experienceElement) => {
    const technologies = selectedExperience?.Technologies || [];
    setSelectedItem({
      ...selectedExperience,
      [experienceElement]: [...technologies, ""],
    });
  };

  const handleExperienceComplexeType = (
    event,
    key,
    selectedExperience,
    experienceElement
  ) => {
    let technologies = selectedExperience?.Technologies || [];
    technologies[key] = event.target.value;
    setSelectedItem({
      ...selectedExperience,
      [experienceElement]: technologies,
    });
  };

  const deleteComplexType = (key, selectedExperience, experienceElement) => {
    const newTechnologies = selectedExperience?.Technologies?.filter(
      (elem, index) => index.toString() !== key
    );

    setSelectedItem({
      ...selectedExperience,
      [experienceElement]: newTechnologies,
    });
  };

  const deleteDescriptionsByExperience = (selectedExperience, key) => {
    const newDescriptArray = selectedExperience?.Description?.filter(
      (elem, index) => index.toString() !== key
    );
    setSelectedItem({ ...selectedExperience, Description: newDescriptArray });
  };

  const addInputDescriptionsByExperience = (selectedExperience) => {
    const newDescriptionElement = { Context: [""], Details: [""] };
    const description = selectedExperience?.Description;
    setSelectedItem({
      ...selectedExperience,
      Description: [...description, newDescriptionElement],
    });
  };

  const addContextToSelectedDescription = (
    selectedExperience,
    descriptionIndex
  ) => {
    const descriptionContext =
      selectedExperience?.Description[descriptionIndex]?.Context || [];
    const oldDescriptionElement =
      selectedExperience?.Description[descriptionIndex];
    const oldDescription = selectedExperience?.Description;
    const newDescription = oldDescription?.map((elem, index) => {
      if (index?.toString() === descriptionIndex) {
        return {
          ...oldDescriptionElement,
          Context: [...descriptionContext, ""],
        };
      } else {
        return elem;
      }
    });
    setSelectedItem({ ...selectedExperience, Description: newDescription });
  };

  function updateSelectedContext(
    selectedExperience,
    descriptionIndex,
    contextIndex,
    event
  ) {
    const descriptionContext =
      selectedExperience?.Description[descriptionIndex]?.Context || [];
    const oldDescriptionElement =
      selectedExperience?.Description[descriptionIndex];
    const oldDescription = selectedExperience?.Description;
    const newDescription = oldDescription?.map((elem, index) => {
      if (index?.toString() === descriptionIndex) {
        const newContext = descriptionContext?.map((contextElem, indexElem) => {
          if (indexElem.toString() === contextIndex.toString()) {
            return event.target.value;
          } else {
            return contextElem;
          }
        });
        return {
          ...oldDescriptionElement,
          Context: newContext,
        };
      } else {
        return elem;
      }
    });
    setSelectedItem({ ...selectedExperience, Description: newDescription });

    // let parentsArray = user.Experiences || [];
    // const index = parentsArray
    //   .map((object) => object.Period)
    //   .indexOf(experienceIndex.Period);
    // if (index !== -1) {
    //   parentsArray[index].Description[descriptionIndex].Context[contextIndex] =
    //     event.target.value;
    // }
    // setUser({ ...user, Experiences: parentsArray });
  }

  const deleteSelectedContext = (
    selectedExperience,
    descriptionIndex,
    contextIndex
  ) => {
    const descriptionContext =
      selectedExperience?.Description[descriptionIndex]?.Context || [];
    const oldDescriptionElement =
      selectedExperience?.Description[descriptionIndex];
    const oldDescription = selectedExperience?.Description;
    const newDescription = oldDescription?.map((elem, index) => {
      if (index?.toString() === descriptionIndex) {
        const newContext = descriptionContext?.filter(
          (contextElem, indexElem) =>
            indexElem.toString() !== contextIndex.toString()
        );
        return {
          ...oldDescriptionElement,
          Context: newContext,
        };
      } else {
        return elem;
      }
    });
    setSelectedItem({ ...selectedExperience, Description: newDescription });
  };

  const addDetailToSelectedDescription = (
    selectedExperience,
    descriptionIndex
  ) => {
    const descriptionDetails =
      selectedExperience?.Description[descriptionIndex]?.Details || [];
    const oldDescriptionElement =
      selectedExperience?.Description[descriptionIndex];
    const oldDescription = selectedExperience?.Description;
    const newDescription = oldDescription?.map((elem, index) => {
      if (index?.toString() === descriptionIndex) {
        return {
          ...oldDescriptionElement,
          Details: [...descriptionDetails, ""],
        };
      } else {
        return elem;
      }
    });
    setSelectedItem({ ...selectedExperience, Description: newDescription });
  };

  const updateSelectedDetail = (
    selectedExperience,
    descriptionIndex,
    detailIndex,
    event
  ) => {
    const updatedSelectedDetail = updateSelectedDetailInEditModalExperirnce(
      selectedExperience,
      descriptionIndex,
      detailIndex,
      event
    );
    setSelectedItem(updatedSelectedDetail);
  };

  const deleteSelectedDetail = (
    selectedExperience,
    descriptionIndex,
    detailIndex
  ) => {
    const filtredDetail = deleteSelectedDetailInEditModalExperirnce(
      selectedExperience,
      descriptionIndex,
      detailIndex
    );
    setSelectedItem(filtredDetail);
  };

  const onDragEnd = ({ result, selectedType, descriptionIndex }) => {
    const finalEditedOrderExperienceParams =
      getFinalEditedOrderExperienceParams({
        selectedItem,
        result,
        selectedType,
        descriptionIndex,
      });
    setSelectedItem(finalEditedOrderExperienceParams);
  };

  const handleEditExperienceButton = () => {
    const oldExperience = dataCollection.Experiences || [];
    const newExperienceList = oldExperience?.map((expElem, index) => {
      if (index.toString() === experienceId.toString()) {
        return selectedItem;
      } else {
        return expElem;
      }
    });
    dispatch(setExperiences(newExperienceList));

    handleClose();
  };

  return (
    <Modal dialogClassName="modal-xl" show={show} onHide={handleClose}>
      <Modal.Header closeButton style={styles.header}>
        <Modal.Title style={styles.title}>
          <img src={experienceIllustration} style={styles.illustration} />
          <h2>Experiences</h2>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          {
            //Check if message failed
            // user.Experiences != null ? (
            dataCollection?.Experiences != null ? (
              <div>
                <div>
                  <Form.Group className="mb-1">
                    {/* Position */}

                    <Grid mb={1}>
                      <TextField
                        placeholder="position"
                        fullWidth
                        helperText="Entrez votre position actuelle ou désirée"
                        inputProps={{ style: { height: "10px" } }}
                        label="Position"
                        variant="outlined"
                        xm={1}
                        size="sm"
                        type="text"
                        value={selectedItem?.Position}
                        onChange={(e) =>
                          handleExperience(e, selectedItem, "Position")
                        }
                      />
                    </Grid>
                  </Form.Group>
                  {/* Entreprise */}
                  <Form.Group
                    className="mb-1"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Grid mb={1}>
                      <TextField
                        placeholder="position"
                        fullWidth
                        helperText="Entrez le nom d'entrprise"
                        inputProps={{ style: { height: "10px" } }}
                        label="Entreprise"
                        variant="outlined"
                        xm={1}
                        size="sm"
                        type="text"
                        value={selectedItem?.Company}
                        onChange={(e) =>
                          handleExperience(e, selectedItem, "Company")
                        }
                      />
                    </Grid>
                  </Form.Group>
                  {/* Période */}
                  <Form.Group
                    className="mb-1"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Grid mb={1}>
                      <TextField
                        placeholder="position"
                        fullWidth
                        helperText="Entrez la période désirée"
                        inputProps={{ style: { height: "10px" } }}
                        label="période"
                        variant="outlined"
                        xm={1}
                        size="sm"
                        type="text"
                        value={selectedItem?.Period}
                        onChange={(e) =>
                          handleExperience(e, selectedItem, "Period")
                        }
                        autoFocus
                      />
                    </Grid>
                  </Form.Group>
                  {/* Technologies */}
                  <div
                    style={{
                      width: "100%",
                      height: "5px",
                      backgroundColor: "#f3f4fa",
                      marginTop: "15px",
                      marginBottom: "20px",
                    }}
                  ></div>

                  <div className="col-md-12">
                    <Form.Group
                      className="mb-1"
                      controlId="exampleForm.ControlInput1"
                    >
                      {selectedItem?.Technologies ? (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ paddingTop: "5px" }}>
                              <span
                                style={{
                                  fontStyle: "oblique",
                                  backgroundColor: "#200A5F",
                                  color: "white",
                                  padding: "5px",
                                  borderRadius: "5px",
                                }}
                              >
                                Technologies:
                              </span>
                            </div>
                            <div>
                              <Grid>
                                <Button
                                  onClick={(e) =>
                                    addForComplexType(
                                      e,
                                      selectedItem,
                                      "Technologies"
                                    )
                                  }
                                  style={{
                                    backgroundColor: "#a9bef0",
                                    color: "#003366",
                                    borderRadius: "5px",
                                  }}
                                >
                                  <AddCircleOutlineIcon fontSize="small" />
                                  <span style={{ fontSize: "x-small" }}>
                                    Ajouter
                                  </span>
                                </Button>
                              </Grid>
                            </div>
                          </div>

                          <DragDropContext
                            onDragEnd={(result) =>
                              onDragEnd({
                                result,
                                selectedType: "technologies",
                              })
                            }
                          >
                            <Droppable droppableId="list">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {Object.entries(
                                    selectedItem.Technologies
                                  ).map(([key, value]) => (
                                    <Draggable
                                      key={key}
                                      draggableId={key.toString()}
                                      index={parseInt(key)}
                                    >
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              paddingTop: "10px",
                                            }}
                                            key={key}
                                          >
                                            <div className="col-md-11">
                                              <Grid mb={1}>
                                                <input
                                                  type="text"
                                                  list="techList"
                                                  value={value}
                                                  placeholder="technologies"
                                                  onChange={(e) =>
                                                    handleExperienceComplexeType(
                                                      e,
                                                      key,
                                                      selectedItem,
                                                      "Technologies"
                                                    )
                                                  }
                                                  autoFocus
                                                  style={{
                                                    backgroundColor: "white",
                                                    border: "1px solid gray",
                                                    borderRadius: "5px",
                                                    padding: "10px",
                                                    fontSize: "16px",
                                                    width: "100%",
                                                    height: "30px",
                                                  }}
                                                />
                                                <datalist id="techList">
                                                  {parametersTechno.map(
                                                    (param) => (
                                                      <option
                                                        key={param.id}
                                                        value={param.value}
                                                      />
                                                    )
                                                  )}
                                                </datalist>
                                              </Grid>
                                            </div>

                                            <div className="col-md-1">
                                              <Grid mb={1}>
                                                <Button
                                                  onClick={() => {
                                                    deleteComplexType(
                                                      key,
                                                      selectedItem,
                                                      "Technologies"
                                                    );
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontSize: "small",
                                                    }}
                                                  >
                                                    <DeleteOutlinedIcon
                                                      style={{
                                                        color: "#DA2889",
                                                      }}
                                                    />
                                                  </span>
                                                </Button>
                                              </Grid>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "5px",
                    backgroundColor: "#f3f4fa",
                  }}
                ></div>
                <div>
                  <Form.Group className="mb-1 mt-3">
                    {/* Description ah */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ paddingTop: "5px" }}>
                        <Form.Label size="sm">
                          <span
                            style={{
                              fontStyle: "oblique",
                              backgroundColor: "#200A5F",
                              color: "white",
                              padding: "5px",
                              borderRadius: "5px",
                            }}
                          >
                            Descriptions:
                          </span>
                        </Form.Label>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            addInputDescriptionsByExperience(selectedItem);
                          }}
                          style={{
                            backgroundColor: "#a9bef0",
                            color: "#003366",
                            borderRadius: "5px",
                          }}
                        >
                          <AddCircleOutlineIcon fontSize="small" />
                          <span style={{ fontSize: "x-small" }}>Ajouter</span>
                        </Button>
                      </div>
                    </div>

                    {selectedItem?.Description ? (
                      <div>
                        <DragDropContext
                          onDragEnd={(result) =>
                            onDragEnd({ result, selectedType: "description" })
                          }
                        >
                          <Droppable droppableId="list">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {Object.entries(selectedItem?.Description).map(
                                  ([key, value], index) => {
                                    return (
                                      <Draggable
                                        key={key}
                                        draggableId={key.toString()}
                                        index={parseInt(key)}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <div
                                              className="col-md-12"
                                              key={key}
                                            >
                                              <Card style={{maxWidth:"90%", margin:"auto"}}>
                                                <div className="row">
                                                  <div className="col-md-11"></div>
                                                  <div className="col-md-1">
                                                    <DeleteButton
                                                      handleClick={() =>
                                                        deleteDescriptionsByExperience(
                                                          selectedItem,
                                                          key
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <CardContent>
                                                  <div
                                                    className="card-number"
                                                    style={{
                                                      fontSize: "30px",
                                                      fontWeight: "bold",

                                                      color: "#DA2889",

                                                      WebkitBackgroundClip:
                                                        "text",

                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {index + 1}
                                                  </div>

                                                  <Grid
                                                    mt={1}
                                                    mb={1}
                                                    style={{
                                                      width: "100%",
                                                    }}
                                                  >
                                                    <div className="row">
                                                      <div>
                                                        <Grid mb={2}>
                                                          <Button
                                                            onClick={(e) =>
                                                              addContextToSelectedDescription(
                                                                selectedItem,
                                                                key,
                                                                e
                                                              )
                                                            }
                                                            sx={{
                                                              bgcolor:
                                                                "#a9bef0",
                                                              color: "#003366",
                                                            }}
                                                            _hover={{
                                                              color: "#000099",
                                                            }}
                                                            _active={{
                                                              color: "#000066",
                                                            }}
                                                            _focus={{
                                                              color: "#0000ff",
                                                            }}
                                                          >
                                                            <AddCircleOutlineIcon fontSize="extraSmall" />
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "xx-small",
                                                              }}
                                                            >
                                                              Ajouter Context
                                                            </span>
                                                          </Button>
                                                        </Grid>
                                                      </div>
                                                    </div>
                                                    <DragDropContext
                                                      onDragEnd={(result) =>
                                                        onDragEnd({
                                                          result,
                                                          selectedType:
                                                            "descriptionContext",
                                                          descriptionIndex:
                                                            index,
                                                        })
                                                      }
                                                    >
                                                      <Droppable droppableId="list">
                                                        {(
                                                          provided,
                                                          snapshot
                                                        ) => (
                                                          <div
                                                            {...provided.droppableProps}
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                          >
                                                            {value.Context?.map(
                                                              (det, keyc) => (
                                                                <Draggable
                                                                  key={keyc}
                                                                  draggableId={keyc.toString()}
                                                                  index={parseInt(
                                                                    keyc
                                                                  )}
                                                                >
                                                                  {(
                                                                    provided,
                                                                    snapshot
                                                                  ) => (
                                                                    <div
                                                                      ref={
                                                                        provided.innerRef
                                                                      }
                                                                      {...provided.draggableProps}
                                                                      {...provided.dragHandleProps}
                                                                    >
                                                                      <div
                                                                        className="row"
                                                                        key={
                                                                          keyc
                                                                        }
                                                                      >
                                                                        <div className="col-md-11">
                                                                          <TextareaAutosize
                                                                            style={{
                                                                              width:
                                                                                "100%",
                                                                              backgroundColor:
                                                                                "#f3f4fa",
                                                                              padding:
                                                                                "3px",
                                                                            }}
                                                                            minRows={
                                                                              3
                                                                            }
                                                                            type="text"
                                                                            value={
                                                                              det
                                                                            }
                                                                            placeholder="Context"
                                                                            autoFocus
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              updateSelectedContext(
                                                                                selectedItem,
                                                                                key,
                                                                                keyc,
                                                                                e
                                                                              )
                                                                            }
                                                                          />
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                          <Grid
                                                                            mb={
                                                                              1
                                                                            }
                                                                          >
                                                                            <Grid
                                                                              mx={
                                                                                -3
                                                                              }
                                                                            >
                                                                              <Button
                                                                                onClick={() => {
                                                                                  deleteSelectedContext(
                                                                                    selectedItem,
                                                                                    key,
                                                                                    keyc
                                                                                  );
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "small",
                                                                                  }}
                                                                                >
                                                                                  <DeleteOutlinedIcon
                                                                                    style={{
                                                                                      color:
                                                                                        "#DA2889",
                                                                                    }}
                                                                                  />
                                                                                </span>
                                                                              </Button>
                                                                            </Grid>
                                                                          </Grid>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </Draggable>
                                                              )
                                                            )}
                                                            {
                                                              provided.placeholder
                                                            }
                                                          </div>
                                                        )}
                                                      </Droppable>
                                                    </DragDropContext>
                                                  </Grid>
                                                  <Stack>
                                                    <div className="row">
                                                      <div className="col-md-12">
                                                        <Grid mb={2}>
                                                          <Button
                                                            onClick={(e) =>
                                                              addDetailToSelectedDescription(
                                                                selectedItem,
                                                                key,
                                                                e
                                                              )
                                                            }
                                                            sx={{
                                                              bgcolor:
                                                                "#a9bef0",
                                                              color: "#003366",
                                                            }}
                                                            _hover={{
                                                              color: "#000099",
                                                            }}
                                                            _active={{
                                                              color: "#000066",
                                                            }}
                                                            _focus={{
                                                              color: "#0000ff",
                                                            }}
                                                          >
                                                            <AddCircleOutlineIcon fontSize="extraSmall" />
                                                            <span
                                                              style={{
                                                                fontSize:
                                                                  "xx-small",
                                                              }}
                                                            >
                                                              Ajouter details
                                                            </span>
                                                          </Button>
                                                        </Grid>
                                                      </div>
                                                    </div>
                                                    <DragDropContext
                                                      onDragEnd={(result) =>
                                                        onDragEnd({
                                                          result,
                                                          selectedType:
                                                            "descriptionDetail",
                                                          descriptionIndex:
                                                            index,
                                                        })
                                                      }
                                                    >
                                                      <Droppable droppableId="list">
                                                        {(
                                                          provided,
                                                          snapshot
                                                        ) => (
                                                          <div
                                                            {...provided.droppableProps}
                                                            ref={
                                                              provided.innerRef
                                                            }
                                                          >
                                                            {value.Details?.map(
                                                              (det, keyd) => (
                                                                <Draggable
                                                                  key={keyd}
                                                                  draggableId={keyd.toString()}
                                                                  index={parseInt(
                                                                    keyd
                                                                  )}
                                                                >
                                                                  {(
                                                                    provided,
                                                                    snapshot
                                                                  ) => (
                                                                    <div
                                                                      ref={
                                                                        provided.innerRef
                                                                      }
                                                                      {...provided.draggableProps}
                                                                      {...provided.dragHandleProps}
                                                                    >
                                                                      <div
                                                                        className="row"
                                                                        key={
                                                                          keyd
                                                                        }
                                                                      >
                                                                        <div className="col-md-11">
                                                                          <TextareaAutosize
                                                                            style={{
                                                                              width:
                                                                                "100%",
                                                                              color:
                                                                                "#200A5F",
                                                                              fontSize:
                                                                                "1rem",
                                                                              lineHeight:
                                                                                "1.2",
                                                                              borderRadius:
                                                                                "4px",
                                                                              padding:
                                                                                "0.5rem",
                                                                              border:
                                                                                "1px solid #ccc",
                                                                            }}
                                                                            minRows={
                                                                              3
                                                                            }
                                                                            type="text"
                                                                            value={
                                                                              det
                                                                            }
                                                                            placeholder="detail"
                                                                            autoFocus
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              updateSelectedDetail(
                                                                                selectedItem,
                                                                                key,
                                                                                keyd,
                                                                                e
                                                                              )
                                                                            }
                                                                          />
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                          <Grid
                                                                            mb={
                                                                              1
                                                                            }
                                                                          >
                                                                            <Grid
                                                                              mx={
                                                                                -3
                                                                              }
                                                                            >
                                                                              <Button
                                                                                onClick={() => {
                                                                                  deleteSelectedDetail(
                                                                                    selectedItem,
                                                                                    key,
                                                                                    keyd
                                                                                  );
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "small",
                                                                                  }}
                                                                                >
                                                                                  <DeleteOutlinedIcon
                                                                                    style={{
                                                                                      color:
                                                                                        "#DA2889",
                                                                                    }}
                                                                                  />
                                                                                </span>
                                                                              </Button>
                                                                            </Grid>
                                                                          </Grid>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  )}
                                                                </Draggable>
                                                              )
                                                            )}
                                                            {
                                                              provided.placeholder
                                                            }
                                                          </div>
                                                        )}
                                                      </Droppable>
                                                    </DragDropContext>
                                                  </Stack>
                                                </CardContent>
                                              </Card>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  }
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Form.Group>
                </div>
              </div>
            ) : (
              <div> </div>
            )
          }
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={handleEditExperienceButton}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
