import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import "bootstrap/dist/css/bootstrap.css";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditIcon from '@mui/icons-material/Edit';
import UpdateContactModal from "./panel/UpdateContactModal";

export default function Contact({ collapsIcon, setCollapsIcon = () => {} }) {
  const dataCollection = useSelector((state) => state.dataCollection);

  const [show, setShow] = useState(false);

  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  //let userObj = JSON.stringify(user, null, 2);
  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  return (
    <Card>
      <CardContent sx={{ bgcolor: "#f3f4fa" }}>
        <div className="row ">
          <div className="col-4 col-md-2 col-lg-2  d-flex  justify-content-center">
            <p style={{ fontstyle: "oblique", fontWeight: "bold" }}> Contact</p>
          </div>
          <div className="col-1 col-md-5 col-lg-5"></div>
          <div className="col-6 col-md-5 col-lg-5 d-flex justify-content-around align-items-center">
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#DA2889",
                  color: "#DA2889",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                onClick={handleShow}
                sx={{ color: "white" }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <EditIcon fontSize="small" />
                  </div>

                  {/* <div>Modifier</div> */}
                </div>
              </Button>
            </div>
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#1976d2",
                  color: "#1976d2",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setCollapsIcon({
                    ...collapsIcon,
                    contact: !collapsIcon.contact,
                  });
                }}
                sx={{ color: "white" }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {collapsIcon.contact ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>

                  {/* <div>Modifier</div> */}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </CardContent>

      {collapsIcon.contact && (
        <Box sx={{ mt: 0, t: 50 }}>
          <Stack spacing={2}>
            <Item>
              <Stack spacing={1} ml={2} mt={1} direction="row">
                <p
                  style={{
                    fontSize: "small",
                    justifyContent: "start",
                    fontstyle: "oblique",
                    fontWeight: "bold",
                  }}
                >
                  Nom:
                </p>

                <div>
                  <p
                    className="item"
                    style={{
                      fontSize: "small",
                      justifyContent: "start",
                    }}
                  >
                    {/* {Object.values(user.Name == null ? 0 : user.Name)[0]} */}
                    {
                      Object.values(
                        dataCollection.Name == null ? 0 : dataCollection.Name
                      )[0]
                    }
                  </p>
                </div>
              </Stack>
              <Stack spacing={1} ml={2} mt={0.5} direction="row">
                <p
                  style={{
                    fontSize: "small",
                    justifyContent: "start",
                    fontstyle: "oblique",
                    fontWeight: "bold",
                  }}
                >
                  Tél:
                </p>
                <div
                  style={{
                    justifyContent: "start",
                  }}
                >
                  {/* <div> */}
                  <p
                    className="item"
                    style={{
                      // fontSize: "small",
                      justifyContent: "start",
                    }}
                  >
                    {/* {Object.values(user.Phone == null ? 0 : user.Phone)[0]} */}
                    {
                      Object.values(
                        dataCollection.Phone == null ? 0 : dataCollection.Phone
                      )[0]
                    }
                  </p>
                  {/* </div> */}

                  {/* {JSON.stringify(user.CvData.Mobile.content, null, 2)} */}
                </div>
              </Stack>
              <Stack spacing={1} ml={2} mt={0.5} direction="row">
                <p
                  style={{
                    fontSize: "small",
                    justifyContent: "start",
                    fontstyle: "oblique",
                    fontWeight: "bold",
                  }}
                >
                  E-mail:
                </p>

                <div>
                  <p
                    className="item"
                    style={{
                      fontSize: "small",
                      justifyContent: "start",
                    }}
                  >
                    {/* {Object.values(user.Email == null ? 0 : user.Email)[0]}{" "} */}
                    {
                      Object.values(
                        dataCollection.Email == null ? 0 : dataCollection.Email
                      )[0]
                    }{" "}
                  </p>
                </div>

                {/* {JSON.stringify(user.CvData.Email.content, null, 2)} */}
              </Stack>
              <Stack spacing={1} ml={2} mt={0.5} direction="row">
                <p
                  style={{
                    fontSize: "small",
                    justifyContent: "start",
                    fontstyle: "oblique",
                    fontWeight: "bold",
                  }}
                >
                  Rôle:
                </p>

                <div>
                  <p
                    className="item"
                    style={{
                      fontSize: "small",
                      justifyContent: "start",
                    }}
                  >
                    {/* {Object.values(user.Profile == null ? 0 : user.Profile)[0]} */}
                    {
                      Object.values(
                        dataCollection.Profile == null
                          ? 0
                          : dataCollection.Profile
                      )[0]
                    }
                  </p>
                </div>

                {/* {JSON.stringify(user.CvData.Email.content, null, 2)} */}
              </Stack>
            </Item>
            <Item>
              <Stack spacing={1} ml={2} mt={-1} direction="row">
                <p
                  style={{
                    fontSize: "small",
                    justifyContent: "start",
                    fontstyle: "oblique",
                    fontWeight: "bold",
                  }}
                >
                  {`Nombre d'années d'expérience(s):`}
                </p>

                <div>
                  <p
                    className="item"
                    style={{
                      fontSize: "small",
                      justifyContent: "start",
                    }}
                  >
                    {
                      Object.values(
                        dataCollection.YearsOfExperience == null
                          ? 0
                          : dataCollection.YearsOfExperience
                      )[0]
                      // Object.values(
                      //   user.YearsOfExperience == null
                      //     ? 0
                      //     : user.YearsOfExperience
                      // )[0]
                    }
                  </p>
                </div>
              </Stack>

              <Stack spacing={2} ml={2} mt={0.5} direction="row">
                <p
                  style={{
                    fontSize: "small",
                    justifyContent: "left",
                    fontstyle: "oblique",
                    fontWeight: "bold",
                  }}
                >
                  Résidence principale:
                </p>

                <div>
                  <p
                    className="item"
                    style={{
                      fontSize: "small",
                      justifyContent: "start",
                    }}
                  >
                    {/* {Object.values(user.Address == null ? 0 : user.Address)[0]} */}
                    {
                      Object.values(
                        dataCollection.Address == null
                          ? 0
                          : dataCollection.Address
                      )[0]
                    }
                  </p>
                </div>
              </Stack>
              <Stack spacing={2} ml={2} mt={0.5} direction="row">
                <p
                  style={{
                    fontSize: "small",
                    justifyContent: "start",
                    fontstyle: "oblique",
                    fontWeight: "bold",
                  }}
                >
                  Linkedin:
                </p>
                <div>
                  <p
                    className="item"
                    style={{
                      fontSize: "small",
                      justifyContent: "start",
                    }}
                  >
                    {
                      Object.values(
                        dataCollection.Linkedin == null
                          ? 0
                          : dataCollection.Linkedin
                      )[0]
                      // Object.values(
                      //   user.Linkedin == null ? 0 : user.Linkedin
                      // )[0]
                    }
                  </p>
                </div>
              </Stack>
              {/* <Stack spacing={2} ml={2} mt={-1} direction="row">
             <div style={{display:"flex"}}>
             <p
                style={{
                  fontSize: "small",
                  justifyContent: "start",
                  fontstyle: "oblique",
                  fontWeight: "bold",
                }}
              >
                Autres:
              </p>

                <div
                  // className="item"
                  style={{
                    justifyContent: "start",
                  }}
                >
                  

                  <ul>
                    {
                      //Check if message failed
                      user.OtherWebSites != null ? (
                        <div>
                          
                          {Object.entries(user.OtherWebSites).map(
                            ([key, value]) => (
                              <li style={{ paddingLeft: 0}} key={key}>
                                {value.Content}
                              </li>
                            )
                          )}
                        </div>
                      ) : (
                        <div> </div>
                      )
                    }
                  </ul>
                </div>
              </div>
            </Stack> */}
            </Item>
          </Stack>
        </Box>
      )}

      <UpdateContactModal show={show} handleClose={handleClose} />
    </Card>
  );
}
