import { useMediaQuery } from 'react-responsive'

const DesktopXL = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ maxWidth: 991 })
  return isDesktop ? children : null
}

const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 577, maxWidth: 768 })
  return isTablet ? children : null
}

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 576 })
  return isMobile ? children : null
}

export {
    DesktopXL,
    Desktop,
    Tablet,
    Mobile,
};