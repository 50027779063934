import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Politique de confidentialité
        </Typography>
        <Typography variant="body1" paragraph>
          Votre vie privée est importante pour nous. Cette politique de confidentialité explique quelles informations nous collectons, comment nous les utilisons et comment nous les protégeons.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          1. Informations que nous collectons
        </Typography>
        <Typography variant="body1" paragraph>
          Nous pouvons collecter des informations personnelles telles que votre nom, adresse e-mail, et toute autre information que vous fournissez volontairement.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          2. Utilisation des informations
        </Typography>
        <Typography variant="body1" paragraph>
          Nous utilisons les informations que nous collectons pour améliorer notre service, personnaliser votre expérience, et communiquer avec vous.
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          3. Protection des informations
        </Typography>
        <Typography variant="body1" paragraph>
          Nous mettons en œuvre des mesures de sécurité pour protéger vos informations personnelles contre l'accès non autorisé, l'altération, la divulgation ou la destruction.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
