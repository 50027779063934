import React from 'react';
import { Box, Container, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const AppFooter = () => {
  const navigate = useNavigate();

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        px: 2,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
      }}
    >
      <Container maxWidth="sm">
        <Box align="center">
          <Link variant="body2" component="button" onClick={() => navigate('/terms-of-service')} sx={{ mr: 2 }}>
            Conditions d'utilisation
          </Link>
          <Link variant="body2" component="button" onClick={() => navigate('/privacy-policy')} sx={{ mr: 2 }}>
            Politique de confidentialité
          </Link>
          <Link variant="body2" component="button" onClick={() => navigate('/template-guide')}>
            Créer son template
          </Link>
        </Box>
        <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
          {'© '}
          {new Date().getFullYear()}
          {' '}
          <Link color="inherit" href="https://www.aptea.net/">
            APTEA
          </Link>
        </Typography>
      </Container>
    </Box>
  );
};

export default AppFooter;
