import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { setCertifications } from "../../../redux/actions/dataCollectionAction";
import UpdateEducationModal from "./UpdateEducationModal";
import AddEducationModal from "./AddEducationModal";

export default function Certificate({
  collapsIcon,
  setCollapsIcon = () => {},
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemId, setSelectedItemId] = useState();

  const [openModal, setopenModal] = useState(false);
  const handleAddCertificationsClose = () => setopenModal(false);

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  const handleSelect = (item) => {
    setSelectedItem(item);
    setShow(true);
  };

  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  const deleteCertifications = (index) => {
    const parentsArray = dataCollection.Certifications || [];
    if (index !== -1) {
      const newListArray = parentsArray.filter(
        (elem, key) => key.toString() !== index
      );
      dispatch(setCertifications(newListArray));
    }
  };

  const ajoueCertification = () => {
    setopenModal(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newList = reorder(
      user.Certifications,
      result.source.index,
      result.destination.index
    );
    setUser({ ...user, Certifications: newList });
  };

  return (
    <Card>
      <CardContent sx={{ bgcolor: "#f3f4fa" }}>
        <div className="row ">
          <div className="col-4 col-md-2 col-lg-2  d-flex justify-content-start align-items-center">
            <p
              className="mt-3"
              style={{
                fontstyle: "oblique",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              Certifications
            </p>
            <span
              style={{
                backgroundColor: "#DA2889",
                maxHeight: "23px",
                minHeight: "23px",
                minWidth: "23px",
                color: "white",
                borderRadius: "50%",
                fontSize: "12px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {dataCollection.Certifications !== null
                ? dataCollection.Certifications.length < 100
                  ? dataCollection.Certifications.length
                  : "99+"
                : 0}
            </span>
          </div>
          <div className="col-3 col-md-7 col-lg-8"></div>
          <div className="col-5 col-md-3 col-lg-2 d-flex justify-content-around align-items-center">
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#DA2889",
                  color: "#DA2889",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                sx={{ color: "white" }}
                onClick={ajoueCertification}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <AddIcon />
                  </div>

                  <div className="d-none d-md-block">Ajouter</div>
                </div>
              </Button>
            </div>
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#1976d2",
                  color: "#1976d2",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setCollapsIcon({
                    ...collapsIcon,
                    certifications: !collapsIcon.certifications,
                  });
                }}
                sx={{ color: "white" }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {collapsIcon.certifications ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>

                  {/* <div>Modifier</div> */}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </CardContent>
      {
        //Check if message failed
        // user.Certifications != null && collapsIcon.certifications ? (
        dataCollection?.Certifications != null && collapsIcon.certifications ? (
          <div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {/* {Object.entries(user.Certifications).map(([key, value]) => ( */}
                    {Object.entries(dataCollection?.Certifications).map(
                      ([key, value]) => (
                        <Draggable
                          key={key}
                          draggableId={key.toString()}
                          index={parseInt(key)}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box key={key}>
                                <div>
                                  <div className="row">
                                    <div className=" col-7 col-md-10">
                                      <Typography
                                        align="left"
                                        spacing={2}
                                        mt={3}
                                        ml={3}
                                        direction="row"
                                      >
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "medium",
                                            color: "#a9bef0",
                                          }}
                                        >
                                          {value.Institute}
                                        </p>
                                      </Typography>
                                      <typography>
                                        <Typography
                                          align="left"
                                          spacing={2}
                                          mt={-2}
                                          ml={3}
                                          direction="row"
                                        >
                                          <p
                                            style={{
                                              fontSize: "small",
                                              justifyContent: "start",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {value.Title}
                                          </p>
                                        </Typography>
                                        <Typography
                                          align="left"
                                          spacing={2}
                                          mt={-2}
                                          ml={3}
                                          direction="row"
                                        >
                                          <p
                                            style={{
                                              fontSize: "small",
                                              justifyContent: "start",
                                            }}
                                          >
                                            {value.Period}
                                          </p>
                                        </Typography>
                                      </typography>
                                    </div>
                                    {/* <div className="col-md-1"></div> */}

                                    <div className="col-5 col-md-2 ">
                                      <div className="row">
                                        <div className=" col-6 col-md-2  mt-4">
                                          <Button
                                            key={key}
                                            style={{
                                              maxHeight: "2.3em",
                                              minHeight: "2.0em",
                                              minWidth: "2em",
                                              textTransform: "capitalize",
                                              border: "2px solid transparent",
                                              borderColor: "#DA2889",
                                              color: "#DA2889",
                                              fontweight: "bold",
                                              backgroundColor: "white",
                                              borderRadius: "5px",
                                            }}
                                            onClick={() => {
                                              handleSelect(value);
                                              setSelectedItemId(key);
                                            }}
                                          >
                                            <CreateIcon
                                              style={{
                                                fontSize: "medium",
                                              }}
                                            />
                                          </Button>
                                        </div>
                                        <div className=" col-6 col-md-2   mt-4 pb-3">
                                          <Button
                                            style={{
                                              maxHeight: "2.3em",
                                              minHeight: "2.0em",
                                              minWidth: "2em",
                                              textTransform: "capitalize",
                                              border: "2px solid transparent",
                                              borderColor: "#686868",
                                              color: "#686868",
                                              fontweight: "bold",
                                              backgroundColor: "white",
                                              borderRadius: "5px",
                                            }}
                                            onClick={() =>
                                              deleteCertifications(key)
                                            }
                                          >
                                            <DeleteIcon
                                              style={{
                                                fontSize: "medium",
                                              }}
                                            />
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Box>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <div> </div>
        )
      }
      {/* Update Certifications */}
      <UpdateEducationModal
        type={"certification"}
        modalTitle={"Cerfitication"}
        show={show}
        selectedItem={selectedItem}
        selectedItemId={selectedItemId}
        setSelectedItem={(item) => setSelectedItem(item)}
        handleClose={handleClose}
      />

      {/* Add Certifications */}

      <AddEducationModal
        type={"certification"}
        modalTitle={"Ajouter une certification"}
        openModal={openModal}
        handleAddFormationClose={handleAddCertificationsClose}
      />
    </Card>
  );
}
