import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Grid from "@mui/material/Grid";
import Contact from "./Contact";
import Langues from "./Langues";
import Hobbies from "./Hobbies";
import Avatar from "@mui/material/Avatar";
import CreateIcon from "@mui/icons-material/Create";
import Button from "@mui/material/Button";
import ButtonGroup from "../mainPage/ButtonGroup";
import Experience from "./panel/Experience";
import Description from "./panel/Description";
import Formation from "./panel/Formation";
import Certificate from "./panel/Certificat";
import Skills from "./panel/Skills";

import ImageProfil from "../../assets/default-user-avatar.jpg";
import { setProfilImage } from "../../redux/actions/dataCollectionAction";

function GlobalCorrection() {
  const experiencesRef = useRef();
  const decriptionRef = useRef();
  const formationRef = useRef();
  const certificatRef = useRef();
  const skillsRef = useRef();
  const imageRef = useRef();
  const profilRef = useRef();
  const autresRef = useRef();
  const [collapsIcon, setCollapsIcon] = useState({
    contact: true,
    skills: true,
    formations: true,
    experiences: true,
    certifications: true,
    description: true,
    hobbies: true,
    Languages: true,
  });
  const dispatch = useDispatch();

  const dataCollection = useSelector((state) => state.dataCollection);

  const getIntoSection = (section) => {
    if (section === "experiences") {
      experiencesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "description") {
      decriptionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "formations") {
      formationRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "certificat") {
      certificatRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "skills") {
      skillsRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "profil" || section === "collapsDown") {
      profilRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (section === "autres") {
      autresRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onChangeProfilImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64ImageString = reader.result.replace(/^data:.+;base64,/, "");
        dispatch(setProfilImage(base64ImageString));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <div>
        <ButtonGroup
          onSectionCall={getIntoSection}
          collapsIcon={collapsIcon}
          setCollapsIcon={(collapsIconState) =>
            setCollapsIcon(collapsIconState)
          }
        />
      </div>
      <div ref={profilRef} className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {dataCollection?.profilImage == "" ? (
                <Avatar sx={{ width: 180, height: 180 }}>
                  {/* <AccountCircleIcon  height={150} width={150}  /> */}
                  <img
                    alt="not fount"
                    width={"200px"}
                    height={"200px"}
                    // style={{
                    //   border: "2px solid #a9bef0",
                    //   // filter: "drop-shadow(0 0 2px #DA2889)",
                    //   borderRadius: "50%",
                    // }}
                    src={ImageProfil}
                  />
                </Avatar>
              ) : (
                <img
                  alt="not fount"
                  width={"200px"}
                  height={"200px"}
                  style={{
                    border: "2px solid #a9bef0",
                    // filter: "drop-shadow(0 0 2px #DA2889)",
                    borderRadius: "50%",
                  }}
                  src={`data:image/jpeg;base64,${dataCollection.profilImage}`}
                  // src={dataCollection.profilImage.imageUrl}
                />
              )}
              <div style={{ height: "150px" }}>
                <Button
                  onClick={() => imageRef.current.click()}
                  style={{
                    maxHeight: "2.3em",
                    minHeight: "2.0em",
                    minWidth: "2em",
                    fontSize: ".85em",
                    textTransform: "capitalize",
                    border: "2px solid transparent",
                    borderColor: "#DA2889",
                    fontweight: "bold",
                    borderRadius: "65px",
                  }}
                  sx={{ bgcolor: "#DA2889", color: "white" }}
                >
                  <div className="row">
                    <div className="col-sm-1 ">
                      <CreateIcon
                        style={{
                          fontSize: "small",
                        }}
                      />
                      <input
                        ref={imageRef}
                        style={{ display: "none" }}
                        type="file"
                        name="selectedImage"
                        onChange={(event) => onChangeProfilImage(event)}
                      />
                    </div>
                  </div>
                </Button>
              </div>
            </div>
            <div>
              {
                //Check if message failed
                dataCollection.Profile != null ? (
                  <div>
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        fontstyle: "oblique",
                        color: "#a9bef0",
                      }}
                    >
                      {Object.values(dataCollection.Profile)[0]}
                    </p>
                  </div>
                ) : (
                  <div> </div>
                )
              }
            </div>
          </div>

          <div className="col-lg-4 col-md-5 col-12 mx-auto">
            <Contact
              collapsIcon={collapsIcon}
              setCollapsIcon={(collapsIconState) =>
                setCollapsIcon(collapsIconState)
              }
            />
          </div>
        </div>
      </div>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 5, sm: 2, md: 3 }}
      ></Grid>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 5, sm: 2, md: 3 }}>
        <Grid item md={0.8}></Grid>
        <Grid item xs={12} md={11}>
          <div style={{ padding: "30px" }}>
            <div style={{ paddingBottom: "20px" }} ref={skillsRef}>
              <Skills
                collapsIcon={collapsIcon}
                setCollapsIcon={(collapsIconState) =>
                  setCollapsIcon(collapsIconState)
                }
              />
            </div>
            <div style={{ paddingBottom: "20px" }} ref={formationRef}>
              <Formation
                collapsIcon={collapsIcon}
                setCollapsIcon={(collapsIconState) =>
                  setCollapsIcon(collapsIconState)
                }
              />
            </div>
            <div style={{ paddingBottom: "20px" }} ref={experiencesRef}>
              <Experience
                collapsIcon={collapsIcon}
                setCollapsIcon={(collapsIconState) =>
                  setCollapsIcon(collapsIconState)
                }
              />
            </div>
            <div style={{ paddingBottom: "20px" }} ref={certificatRef}>
              <Certificate
                collapsIcon={collapsIcon}
                setCollapsIcon={(collapsIconState) =>
                  setCollapsIcon(collapsIconState)
                }
              />
            </div>
            <div style={{ paddingBottom: "20px" }} ref={decriptionRef}>
              <Description
                collapsIcon={collapsIcon}
                setCollapsIcon={(collapsIconState) =>
                  setCollapsIcon(collapsIconState)
                }
              />
            </div>
            <div>
              <div ref={autresRef} className="pb-4">
                <Hobbies
                  collapsIcon={collapsIcon}
                  setCollapsIcon={(collapsIconState) =>
                    setCollapsIcon(collapsIconState)
                  }
                />
              </div>
              <div>
                <Langues
                  collapsIcon={collapsIcon}
                  setCollapsIcon={(collapsIconState) =>
                    setCollapsIcon(collapsIconState)
                  }
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default GlobalCorrection;
