import React, { useState, useEffect } from 'react';
import mammoth from 'mammoth';
import { Container, Typography, Box, Paper } from '@mui/material';

const TemplateGuide = () => {
  const file = "../Template/CV Modèle APTEA.docx";

  const handleFileLoad = async () => {
    try {
      const response = await fetch(file);
      const arrayBuffer = await response.arrayBuffer();
      const result = await mammoth.convertToHtml({ arrayBuffer });
      return result.value;
    } catch (error) {
      console.error("Error loading file:", error);
      return "<p>Failed to load file preview.</p>";
    }
  };

  const [previewHtml, setPreviewHtml] = useState("");

  useEffect(() => {
    handleFileLoad().then(setPreviewHtml);
  }, []);

  return (
    <Container maxWidth="md" sx={{ my: 3 }}>
      <Typography variant="h4" gutterBottom>
        Guide pour créer votre template
      </Typography>
      <Box>
        <Typography variant="h5" gutterBottom>
          Introduction à Jinja2
        </Typography>
        <Typography variant="body1" paragraph>
          Jinja2 est un moteur de template. Il vous permet d'insérer des variables, des conditions et des boucles dans vos fichiers.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Exemple de Balises Jinja2
        </Typography>
        <Paper elevation={3} style={{ padding: "16px", marginTop: "16px" }}>
          <pre style={{margin:"0px"}}>
            {`{{ variable }} - pour afficher une variable
{% if condition %} - pour une condition
{% for item in list %} - pour une boucle`}
          </pre>
        </Paper>
      </Box>
      <Box marginTop={4}>
        <Typography variant="h5" gutterBottom>
          Exemple de template
        </Typography>
        <Paper elevation={3} style={{ padding: "16px", marginTop: "16px" }}>
          <div
            dangerouslySetInnerHTML={{ __html: previewHtml }}
            style={{ padding: "16px" }}
          />
        </Paper>
      </Box>
    </Container>
  );
};

export default TemplateGuide;
