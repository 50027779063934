import React, { useState, useEffect } from "react";
import FourKIcon from "@mui/icons-material/FourK";

import "./ButtonGroup.css";
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import WorkIcon from '@mui/icons-material/Work';
import DescriptionIcon from '@mui/icons-material/Description';
import SchoolIcon from '@mui/icons-material/School';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import BuildIcon from '@mui/icons-material/Build';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function ButtonGroup({
  onSectionCall = () => {},
  collapsIcon,
  setCollapsIcon = () => {},
}) {
  const [navStyle, setNavStyle] = useState({
    experiences: "nav-link scrollto",
    formations: "nav-link scrollto",
    certificat: "nav-link scrollto",
    skills: "nav-link scrollto",
    profil: "nav-link scrollto",
    collapsDown: "nav-link scrollto",
    autres: "nav-link scrollto",
    description: "nav-link scrollto",
  });
  const [globalCollapsIcon, setGlobalCollapsIcon] = useState(true);

  const onNavClick = (section) => {
    onSectionCall(section);
    if (section === "experiences") {
      setNavStyle({
        ...navStyle,
        experiences: "nav-link scrollto active",
        formations: "nav-link scrollto",
        certificat: "nav-link scrollto",
        skills: "nav-link scrollto",
        profil: "nav-link scrollto",
        collapsDown: "nav-link scrollto",
        autres: "nav-link scrollto",
        description: "nav-link scrollto",
      });
    }

    if (section === "description") {
      setNavStyle({
        ...navStyle,
        description: "nav-link scrollto active",
        experiences: "nav-link scrollto ",
        formations: "nav-link scrollto",
        certificat: "nav-link scrollto",
        skills: "nav-link scrollto",
        profil: "nav-link scrollto",
        collapsDown: "nav-link scrollto",
        autres: "nav-link scrollto",
      });
    }
    if (section === "formations") {
      setNavStyle({
        ...navStyle,
        formations: "nav-link scrollto active",
        description: "nav-link scrollto ",
        experiences: "nav-link scrollto ",
        certificat: "nav-link scrollto",
        skills: "nav-link scrollto",
        profil: "nav-link scrollto",
        collapsDown: "nav-link scrollto",
        autres: "nav-link scrollto",
      });
    }
    if (section === "certificat") {
      setNavStyle({
        ...navStyle,
        certificat: "nav-link scrollto active",
        formations: "nav-link scrollto ",
        description: "nav-link scrollto ",
        experiences: "nav-link scrollto ",
        skills: "nav-link scrollto",
        profil: "nav-link scrollto",
        collapsDown: "nav-link scrollto",
        autres: "nav-link scrollto",
      });
    }
    if (section === "skills") {
      setNavStyle({
        ...navStyle,
        skills: "nav-link scrollto active",
        certificat: "nav-link scrollto ",
        formations: "nav-link scrollto ",
        description: "nav-link scrollto ",
        experiences: "nav-link scrollto ",
        profil: "nav-link scrollto",
        collapsDown: "nav-link scrollto",
        autres: "nav-link scrollto",
      });
    }
    if (section === "profil") {
      setNavStyle({
        ...navStyle,
        profil: "nav-link scrollto active",
        collapsDown: "nav-link scrollto ",
        skills: "nav-link scrollto ",
        certificat: "nav-link scrollto ",
        formations: "nav-link scrollto ",
        description: "nav-link scrollto ",
        experiences: "nav-link scrollto ",
        autres: "nav-link scrollto",
      });
    }
    if (section === "autres") {
      setNavStyle({
        ...navStyle,
        autres: "nav-link scrollto active",
        profil: "nav-link scrollto ",
        collapsDown: "nav-link scrollto ",
        skills: "nav-link scrollto ",
        certificat: "nav-link scrollto ",
        formations: "nav-link scrollto ",
        description: "nav-link scrollto ",
        experiences: "nav-link scrollto ",
      });
    }
    if (section === "collapsDown") {
      setGlobalCollapsIcon(!globalCollapsIcon);
      if (globalCollapsIcon) {
        setCollapsIcon(
          Object.keys(collapsIcon).reduce((acc, key) => {
            acc[key] = false;
            return acc;
          }, {})
        );
      } else {
        setCollapsIcon(
          Object.keys(collapsIcon).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {})
        );
      }
      setNavStyle({
        ...navStyle,
        collapsDown: "nav-link scrollto active",
        autres: "nav-link scrollto",
        profil: "nav-link scrollto ",
        skills: "nav-link scrollto ",
        certificat: "nav-link scrollto ",
        formations: "nav-link scrollto ",
        description: "nav-link scrollto ",
        experiences: "nav-link scrollto ",
      });
    }
  };
  useEffect(() => {
    const allFalse = Object.values(collapsIcon).every(
      (value) => value === false
    );
    if (allFalse) {
      setGlobalCollapsIcon(false);
    }
    const allTrue = Object.values(collapsIcon).every((value) => value === true);
    if (allTrue) {
      setGlobalCollapsIcon(true);
    }
    const atLeastOneTrue = Object.values(collapsIcon).some(
      (value) => value === true
    );
    if (atLeastOneTrue) {
      setGlobalCollapsIcon(true);
    }
    const atLeastOneFalse = Object.values(collapsIcon).some(
      (value) => value === false
    );
    if (atLeastOneFalse) {
      setGlobalCollapsIcon(false);
    }
  }, [collapsIcon]);

  return (
    <div>
      <header style={{ overflow: "hidden" }} id="header">
        <nav id="navbar" className="navbar nav-menu">
          <ul>
            <li>
              <a
                onClick={() => onNavClick("collapsDown")}
                className={navStyle.collapsDown}
              >
                {globalCollapsIcon ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                <span>{globalCollapsIcon ? "Réduire" : "Étendre"}</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => onNavClick("profil")}
                className={navStyle.profil}
              >
                <PersonIcon /> <span>Profil</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => onNavClick("skills")}
                className={navStyle.skills}
              >
                <BuildIcon /> <span>Compétences</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => onNavClick("formations")}
                className={navStyle.formations}
              >
                <SchoolIcon /> <span>Formations</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => onNavClick("experiences")}
                className={navStyle.experiences}
              >
                <WorkIcon /> <span>Expérience</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => onNavClick("certificat")}
                className={navStyle.certificat}
              >
                <EmojiObjectsIcon /> <span>Certificat</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => onNavClick("description")}
                className={navStyle.description}
              >
                <DescriptionIcon /> <span>Description</span>
              </a>
            </li>
            <li>
              <a
                onClick={() => onNavClick("autres")}
                className={navStyle.autres}
              >
                <MoreVertIcon /> <span>Autres</span>
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </div>
  );
}

export default ButtonGroup;
