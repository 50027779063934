import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import { useEffect } from "react";
import { CvContext } from "../../../context/CvContext";
import AddIcon from '@mui/icons-material/Add';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AddExperienceModal from "./AddExperienceModal";
import UpdateExperienceModal from "./UpdateExperienceModal";
import {
  setDataCollection,
  setExperiences,
} from "../../../redux/actions/dataCollectionAction";

export default function Experience({ collapsIcon, setCollapsIcon = () => {} }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [openModal, setopenModal] = useState(false);
  const handleAddExperienceClose = () => setopenModal(false);
  const [selectedItem, setSelectedItem] = useState();
  const [experienceId, setExperienceId] = useState(null);

  const dispatch = useDispatch();

  const dataCollection = useSelector((state) => state.dataCollection);

  const handleSelect = (item) => {
    setSelectedItem(item);
    setShow(true);
  };

  if (window.UndefinedVariable) {
    Object.assign(window.UndefinedVariable, {});
  }

  const deleteExperience = (key) => {
    const experiencesList = dataCollection?.Experiences || [];
    const filtredExperiencesList = experiencesList?.filter(
      (expElem, index) => index.toString() !== key.toString()
    );
    dispatch(setExperiences(filtredExperiencesList));
  };
  const [parametersLang, setParametersLang] = useState([]);
  const [parametersTechno, setParametersTechno] = useState([]);
  useEffect(() => {
    const parametersFromLocalStorage = JSON.parse(
      localStorage.getItem("parameters") || "[]"
    );

    const parametersReduced = parametersFromLocalStorage.reduce(
      (acc, parameter) => {
        if (parameter.name === "techno") {
          return {
            ...acc,
            parametersTechno: [...acc.parametersTechno, parameter],
          };
        } else if (parameter.name === "lang") {
          return { ...acc, parametersLang: [...acc.parametersLang, parameter] };
        }
        return acc;
      },
      { parametersLang: [], parametersTechno: [] }
    );

    setParametersLang(parametersReduced.parametersLang);
    setParametersTechno(parametersReduced.parametersTechno);
    // console.log("parametersLang:", parametersReduced.parametersLang);
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const list = dataCollection?.Experiences;

    const newList = reorder(
      list,
      result.source.index,
      result.destination.index
    );
    dispatch(setDataCollection({ ...dataCollection, Experiences: newList }));
  };

  return (
    <Card>
      <CardContent sx={{ bgcolor: "#f3f4fa" }}>
        <div className="row ">
          <div className="col-4 col-md-2 col-lg-2  d-flex justify-content-start align-items-center">
            <p
              className="mt-3"
              style={{
                fontstyle: "oblique",
                fontWeight: "bold",
                marginRight: "10px",
              }}
            >
              Expériences
            </p>
            <span
              style={{
                backgroundColor: "#DA2889",
                maxHeight: "23px",
                minHeight: "23px",
                minWidth: "23px",
                color: "white",
                borderRadius: "50%",
                fontSize: "12px",
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {dataCollection.Experiences !== null
                ? dataCollection.Experiences.length < 100
                  ? dataCollection.Experiences.length
                  : "99+"
                : 0}
            </span>
          </div>
          <div className="col-3 col-md-7 col-lg-8"></div>
          <div className="col-5 col-md-3 col-lg-2 d-flex justify-content-around align-items-center">
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#DA2889",
                  color: "#DA2889",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                sx={{ color: "white" }}
                onClick={() => setopenModal(true)}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ paddingRight: "1px" }}>
                    {/* <CreateIcon 
                    style={{
                      fontSize: "small",
                    }}
                  /> */}
                    <AddIcon />
                  </div>

                  <div className="d-none d-md-block">Ajouter</div>
                </div>
              </Button>
            </div>
            <div>
              <Button
                style={{
                  maxHeight: "2.3em",
                  minHeight: "2.0em",
                  minWidth: "2em",
                  fontSize: ".85em",
                  textTransform: "capitalize",
                  border: "2px solid transparent",
                  borderColor: "#1976d2",
                  color: "#1976d2",
                  fontweight: "bold",
                  backgroundColor: "white",
                  borderRadius: "5px",
                }}
                onClick={() => {
                  setCollapsIcon({
                    ...collapsIcon,
                    experiences: !collapsIcon.experiences,
                  });
                }}
                sx={{ color: "white" }}
              >
                <div
                  style={{
                    display: "flex ",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {collapsIcon.experiences ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>

                  {/* <div>Modifier</div> */}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </CardContent>

      {/* Comment:  Experience Block Element  */}

      {
        //Check if message failed
        dataCollection.Experiences != null && collapsIcon.experiences ? (
          <div>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <Droppable droppableId="list">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {Object.entries(dataCollection?.Experiences).map(
                      ([key, value]) => (
                        <Draggable
                          key={key}
                          draggableId={key.toString()}
                          index={parseInt(key)}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Box key={key} ml={1} mt={2}>
                                <div>
                                  {/* Comment: Profil & button update & button delete   */}
                                  <Box>
                                    <div className="row">
                                      <div className="col-6 col-md-10 d-flex justify-content-start align-items-start">
                                        <Box>
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "medium",
                                              color: "#a9bef0",
                                            }}
                                          >
                                            {value.Position}
                                          </p>
                                        </Box>
                                      </div>
                                      {/* <div className="col-md-5"></div> */}
                                      <div className="col-5 col-md-2">
                                        <div className="row">
                                          <div className="col-6 col-md-6 mx-auto mb-2">
                                            <Button
                                              style={{
                                                maxHeight: "2.3em",
                                                minHeight: "2.0em",
                                                minWidth: "2em",
                                                textTransform: "capitalize",
                                                border: "2px solid transparent",
                                                borderColor: "#DA2889",
                                                color: "#DA2889",
                                                fontweight: "bold",
                                                backgroundColor: "white",
                                                borderRadius: "5px",
                                              }}
                                              onClick={() => {
                                                setExperienceId(key);
                                                handleSelect(value);
                                              }}
                                            >
                                              <CreateIcon
                                                style={{
                                                  fontSize: "medium",
                                                }}
                                              />
                                            </Button>
                                          </div>
                                          <div className="col-6 col-md-6 mx-auto mb-2 pb-3">
                                            <Button
                                              style={{
                                                maxHeight: "2.3em",
                                                minHeight: "2.0em",
                                                minWidth: "2em",
                                                maxHeight: "100%",
                                                minHeight: "100%",
                                                textTransform: "capitalize",
                                                border: "2px solid transparent",
                                                borderColor: "#686868",
                                                color: "#686868",
                                                fontweight: "bold",
                                                backgroundColor: "white",
                                                borderRadius: "5px",
                                              }}
                                              onClick={() =>
                                                deleteExperience(key)
                                              }
                                            >
                                              <DeleteIcon
                                                style={{
                                                  fontSize: "medium",
                                                }}
                                              />
                                            </Button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Box>
                                  <Box>
                                    <Stack spacing={2} ml={1} direction="row">
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          fontSize: "small",
                                          color: "#DA2889",
                                          justifyContent: "start",
                                          paddingLeft: "15px",
                                        }}
                                      >
                                        {value.Company}
                                      </p>
                                    </Stack>
                                  </Box>
                                  {/* Periode */}
                                  <Box mt={1}>
                                    <Stack spacing={2} ml={1} direction="row">
                                      <p
                                        style={{
                                          fontSize: "small",
                                          justifyContent: "start",
                                          paddingLeft: "15px",
                                        }}
                                      >
                                        {value.Period}
                                      </p>
                                    </Stack>
                                  </Box>
                                  {/* Technologies */}
                                  <Box>
                                    <Stack spacing={2} ml={1} direction="row">
                                      <p
                                        style={{
                                          fontSize: "small",
                                          fontWeight: "bold",
                                          justifyContent: "start",
                                          paddingLeft: "15px",
                                        }}
                                      >
                                        Technologies
                                      </p>
                                    </Stack>
                                  </Box>
                                  <Stack spacing={1} ml={1} mt={-1} mb={2}>
                                    <div
                                      style={{
                                        padding: "5px",
                                        margin: "5px",
                                        width: "100%",
                                        display: "flex",
                                        width: "100%",
                                        flexWrap: "wrap",
                                      }}
                                    >
                                      {value.Technologies?.filter((x) => x).map(
                                        (x, technologieValueIndex) => (
                                          <div
                                            style={{ padding: "5px" }}
                                            key={technologieValueIndex}
                                          >
                                            <Chip
                                              label={x}
                                              style={{
                                                backgroundColor: "#f3f4fa",
                                              }}
                                            ></Chip>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Stack>

                                  {/* Description */}
                                  <Box mt={0}>
                                    {value.Description?.filter((x) => x).map(
                                      (x, descriptionValueIndex) => (
                                        <Box
                                          key={descriptionValueIndex}
                                          align="left"
                                        >
                                          <Stack
                                            spacing={2}
                                            ml={3}
                                            mt={2}
                                            direction="row"
                                          >
                                            <div
                                              style={{
                                                justifyContent: "start",
                                              }}
                                            >
                                              {x.Context?.filter((y) => y).map(
                                                (y, contextIndex) => (
                                                  <div key={contextIndex}>
                                                    <span
                                                      style={{
                                                        fontSize: "small",
                                                        fontWeight: "bold",
                                                        justifyContent: "start",
                                                      }}
                                                    >
                                                      {y}
                                                    </span>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          </Stack>
                                          <Stack spacing={2} ml={3} mt={1}>
                                            <ul>
                                              {x.Details?.filter(
                                                (det) => det
                                              ).map((det, key3) => (
                                                <li key={key3}>
                                                  <p
                                                    style={{
                                                      fontSize: "small",
                                                      justifyContent: "start",
                                                    }}
                                                  >
                                                    {det}
                                                  </p>
                                                </li>
                                              ))}
                                            </ul>
                                          </Stack>
                                        </Box>
                                      )
                                    )}
                                  </Box>
                                  <Box mt={-2}></Box>
                                  <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-8">
                                      <hr
                                        color="blue"
                                        style={{ color: "#a9bef0" }}
                                      ></hr>{" "}
                                    </div>
                                    <div className="col-md-2"></div>
                                  </div>
                                </div>
                                <Box mt={-2}></Box>
                              </Box>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        ) : (
          <div> </div>
        )
      }

      {/* Update Experience  */}

      <UpdateExperienceModal
        show={show}
        selectedItem={selectedItem}
        experienceId={experienceId}
        handleClose={handleClose}
        setSelectedItem={(item) => setSelectedItem(item)}
      />

      {/* Add Experience  */}

      <AddExperienceModal
        openModal={openModal}
        handleAddExperienceClose={handleAddExperienceClose}
      />
    </Card>
  );
}
