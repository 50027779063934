import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { setDescription } from "../../../redux/actions/dataCollectionAction";
import { TextareaAutosize } from "@mui/material";

export default function UpdateDescriptionModal({
  show,
  handleClose = () => {},
}) {
  const [descriptionProfil, setDescriptionProfil] = useState({});

  const dataCollection = useSelector((state) => state.dataCollection);

  const dispatch = useDispatch();

  const handleEditButton = () => {
    dispatch(setDescription(descriptionProfil));
    onCloseModal();
  };
  const onChangeDescription = (event) => {
    setDescriptionProfil({ Content: event.target.value });
  };

  const onCloseModal = () => {
    setDescriptionProfil({ Content: "" });
    handleClose();
  };
  useEffect(() => {
    setDescriptionProfil(dataCollection.Description);
  }, [show]);

  return (
    <Modal show={show} onHide={onCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title
          style={{
            bgcolor: "#200A5F",
            color: "#200A5F",
          }}
        >
          Description
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <div>
              <div className="row">
                <div className="col-md-10">
                  <TextareaAutosize
                    style={{ width: "100%" }}
                    minRows={3}
                    type="text"
                    // value={
                    //   Object.values(
                    //     user.Description == null ? 0 : user.Description
                    //   )[0]
                    // }
                    value={descriptionProfil?.Content}
                    placeholder="description"
                    autoFocus
                    onChange={(event) => onChangeDescription(event)}
                  />
                </div>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-xs-3"></div>

        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid transparent",
            borderColor: "#DA2889",
            fontweight: "bold",
            borderRadius: "5px",
          }}
          sx={{ bgcolor: "#DA2889", color: "white" }}
          onClick={handleEditButton}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
