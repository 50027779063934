import React, { useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useMediaQuery } from "react-responsive";
import { DesktopXL, Desktop, Tablet, Mobile } from "../../utilities/Responsive";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./PdfReader.css";

function PdfReader({ pdfFile = null }) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    return () => {
      setNumPages(null);
    };
  }, []);

  return (
    <div>
        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdfReaderContainer"
          >
          {Array.from(new Array(numPages), (el, index) => (
            <div key={index}>
              <DesktopXL>
                <Page
                  key={`page_${index * Math.random() * 7}`}
                  pageNumber={index + 1}
                  width={
                    screen.width * 0.6
                  }
                />
              </DesktopXL>
              
              <Desktop>
                <Page
                  key={`page_${index * Math.random() * 7}`}
                  pageNumber={index + 1}
                  width={
                    screen.width * 0.4
                  }
                />
              </Desktop>

              <Tablet>
                <Page
                  key={`page_${index * Math.random() * 9}`}
                  pageNumber={index + 1}
                  width={
                    screen.width * 0.3
                  }
                  />
              </Tablet>
              
              <Mobile>
                <Page
                  key={`page_${index * Math.random() * 8}`}
                  pageNumber={index + 1}
                  width={
                    screen.width * 0.1
                  }
                  />
              </Mobile>
            </div>
          ))}
        </Document>


      {/* {isTabletOrMobile ? (
        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdfReaderContainer"
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={
                isTabletOrMobile ? screen.width * 0.74 : screen.width * 0.74
              }
            />
          ))}
        </Document>
      ) : (
        <Document
          file={pdfFile}
          onLoadSuccess={onDocumentLoadSuccess}
          className="pdfReaderContainer"
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              width={
                isTabletOrMobile ? screen.width * 0.74 : screen.width * 0.74
              }
            />
          ))}
        </Document>
      )} */}
    </div>
  );
}

export default PdfReader;
