import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { setContactFooter } from "../../redux/actions/contactFooterAction";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { jwtDecode } from "jwt-decode";
import { Button, Grid, Tooltip } from "@mui/material";

export default function UpdateContactFooterModal({
  show,
  handleFootClose = () => {},
}) {
  const initialState = {
    Adresse: {
      Content: "e",
    },
    telephoneOne: { Content: "" },
    telephoneTwo: { Content: "" },
    EntrepriseType: { Content: "" },
    PublicRegister: { Content: "" },
    Mail: { Content: "" },
    InternetLink: { Content: "" },
    NomEntreprise: { Content: "" },
    CouleurLien: { Content: "" },
  };
  const [footerFields, setFooterFields] = useState(initialState);
  const [premiumEnabled, setPremiumEnabled] = useState(false);

  const contactFooter = useSelector((state) => state.contactFooter);
  const userToken = useSelector((state) => state.user.token);

  const dispatch = useDispatch();

  const onChangecontactFooter = (event, member, item) => {
    setFooterFields((prevState) => ({
      ...prevState,
      [member]: {
        Content: event.target.value,
      },
    }));
  };

  const onRegisterButton = () => {
    dispatch(
      setContactFooter({
        ...contactFooter,
        ...footerFields,
      })
    );
    handleFootClose();
  };

  useEffect(() => {
    setFooterFields(contactFooter);
  }, [show]);

  useEffect(() => {
    if (userToken) {
      const decodedToken = jwtDecode(userToken);
      setPremiumEnabled(decodedToken.is_premium);
    } else {
      setPremiumEnabled(false);
    }
  }, [userToken]);

  return (
    <Modal show={show} onHide={handleFootClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div
            style={{
              backgroundColor: "white",
              textAlign: "center",
            }}
          >
            Pied de Page
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="exampleForm.ControlInput1">
            <div className="row">
              <div className="col-md-10">
                <Grid container spacing={2}>
                  <label style={{ display: "block", fontWeight: "bold" }}>
                    Adresse
                  </label>
                  <Grid item xs={12}>
                    <Form.Control
                      size="sm"
                      type="text"
                      onChange={(event) =>
                        onChangecontactFooter(event, "Adresse")
                      }
                      value={Object.values(footerFields.Adresse)[0]}
                      placeholder="Adresse"
                    />
                  </Grid>
                  <label style={{ display: "block", fontWeight: "bold" }}>
                    Téléphone 1
                  </label>
                  <Grid item xs={12}>
                    <Form.Control
                      size="sm"
                      type="text"
                      onChange={(event) =>
                        onChangecontactFooter(event, "telephoneOne")
                      }
                      value={Object.values(footerFields.telephoneOne)[0]}
                      placeholder="Téléphone 1"
                    />
                  </Grid>
                  <label style={{ display: "block", fontWeight: "bold" }}>
                    Téléphone 2
                  </label>
                  <Grid item xs={12}>
                    <Form.Control
                      size="sm"
                      type="text"
                      onChange={(event) =>
                        onChangecontactFooter(event, "telephoneTwo")
                      }
                      value={Object.values(footerFields.telephoneTwo)[0]}
                      placeholder="Téléphone 2"
                    />
                  </Grid>
                  <label style={{ display: "block", fontWeight: "bold" }}>
                    Type d entreprise
                  </label>
                  <Grid item xs={12}>
                    <Form.Control
                      size="sm"
                      type="text"
                      onChange={(event) =>
                        onChangecontactFooter(event, "EntrepriseType")
                      }
                      value={Object.values(footerFields.EntrepriseType)[0]}
                      placeholder="Type d'entreprise"
                    />
                  </Grid>
                  <label style={{ display: "block", fontWeight: "bold" }}>
                    Register Publique
                  </label>
                  <Grid item xs={12}>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder=" Register Publique"
                      onChange={(event) =>
                        onChangecontactFooter(event, "PublicRegister")
                      }
                      value={Object.values(footerFields.PublicRegister)[0]}
                    />
                  </Grid>
                  <label style={{ display: "block", fontWeight: "bold" }}>
                    Mail
                  </label>
                  <Grid item xs={12}>
                    <Form.Control
                      size="sm"
                      type="text"
                      onChange={(event) => onChangecontactFooter(event, "Mail")}
                      value={Object.values(footerFields.Mail)[0]}
                      placeholder="mail"
                    />
                  </Grid>
                  <label style={{ display: "block", fontWeight: "bold" }}>
                    Lien internet
                  </label>
                  <Grid item xs={12}>
                    <Form.Control
                      size="sm"
                      type="text"
                      onChange={(event) =>
                        onChangecontactFooter(event, "InternetLink")
                      }
                      value={Object.values(footerFields.InternetLink)[0]}
                      placeholder="lien internet"
                    />
                  </Grid>
                  <label style={{ display: "block", fontWeight: "bold" }}>
                    Nom Entreprise
                  </label>
                  <Tooltip
                    title="Premium requis"
                    arrow
                    style={{ marginLeft: "5px" }}
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>
                  <Grid item xs={12}>
                    <Form.Control
                      size="sm"
                      type="text"
                      onChange={(event) =>
                        onChangecontactFooter(event, "NomEntreprise")
                      }
                      value={Object.values(footerFields.NomEntreprise)[0]}
                      placeholder="Nom Entreprise"
                      disabled={!premiumEnabled}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                <Form.Control
                  size="sm"
                  type="text"
                  onChange={(event) =>
                    onChangecontactFooter("CouleurLien")
                  }
                  value={Object.values(footerFields.InternetLink)[0]}
                  placeholder="Couleur mail & Lien"
                  autoFocus
                />
              </Grid> */}
                </Grid>
              </div>
            </div>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{
            textTransform: "capitalize",
            border: "2px solid #DA2889",
            borderRadius: "5px",
            fontWeight: "bold",
            backgroundColor: "#DA2889",
            color: "white",
          }}
          onClick={onRegisterButton}
        >
          Enregistrer Changements
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
